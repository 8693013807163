<div class="headbar showSideNav">
  <div style="flex-direction: column" class=" h-100 d-flex align-items-center flex-row bd-highlight">
    <div>
      <div class="logo-container" (click)="websiteRedirect()"></div>
    </div>
    <div class="p-2 bd-highlight flex-fill" style="position: relative">
      <div class ="font-settings">
        <span class="name-settings">Welcome<br><span class="font-italic">{{user}}</span></span>
        <ng-container *ngIf="!enableJointUsersMenuList; else elB">
          <span style="left: 48%;position: absolute;" (click)="dt()" class=" p-8 bd-highlight dp-container" matTooltip="Your individual IHL account information that is used for logging into the application" stepPosition="right" title="Profile Settings" text="Your individual IHL account information that is used for logging into the application. You can update your profile information here." joyrideStep="profile_ui_walk">
            <span style="height:60px; width:70px; display:inline-block; position: relative;">
              <span style="font-size:15px; position: absolute; left:0; top:-3px;white-space: nowrap;">My Profile</span><span style="position: absolute; left:105%; font-size:13px; top:-3px;"><i class="fas fa-edit"></i></span>
              <img [src]= 'genderImg' (click)="dt()" style = "position:absolute;left:15px;top:18px;width: 43px;height: 43px;border-radius: 50%;" alt="profile picture" />
            </span>
          </span>
        </ng-container>
        <ng-template #elB>
          <span style="left: 46%;position: absolute;" [matMenuTriggerFor]="belowMenu" class="p-8 bd-highlight dp-container" matTooltip="Your individual IHL account information that is used for logging into the application" stepPosition="right" title="Profile Settings" text="Your individual IHL account information that is used for logging into the application. You can update your profile information here." joyrideStep="profile_ui_walk">
            <span style="height:60px; width:70px; display:inline-block; position: relative;">
              <span style="font-size:15px; position: absolute; left:0; top:-3px;white-space: nowrap;">My Profile</span><span style="position: absolute; left:105%; font-size:13px; top:-3px;"><i class="fas fa-edit"></i></span>
              <img [src]= 'genderImg'  style = "position:absolute;left:15px;top:18px;width: 43px;height: 43px;border-radius: 50%;" alt="profile picture" />
            </span>
          </span>
          <mat-menu #belowMenu="matMenu" yPosition="below" backdropClass="sg-vertical-sub-menu" class="join-account-menu">
            <manage-account [userDetails]="userData" (onLogOut)="logOut()" (onEmptyUserList)="onEmptyUserList($event)"></manage-account>
          </mat-menu>
        </ng-template>
        <!-- <span style="left: 46%;position: absolute;" [matMenuTriggerFor]="belowMenu" class="p-8 bd-highlight dp-container" matTooltip="Your individual IHL account information that is used for logging into the application" stepPosition="right" title="Profile Settings" text="Your individual IHL account information that is used for logging into the application. You can update your profile information here." joyrideStep="profile_ui_walk">
          <span style="height:60px; width:70px; display:inline-block; position: relative;">
            <span style="font-size:15px; position: absolute; left:0; top:-3px;white-space: nowrap;">My Profile</span><span style="position: absolute; left:105%; font-size:13px; top:-3px;"><i class="fas fa-edit"></i></span>
            <img [src]= 'genderImg'  style = "position:absolute;left:15px;top:18px;width: 43px;height: 43px;border-radius: 50%;" alt="profile picture" />
          </span>
        </span>
        <mat-menu #belowMenu="matMenu" yPosition="below" backdropClass="sg-vertical-sub-menu" class="join-account-menu">
          <manage-account *ngIf="innerWidth > 500" [userDetails]="_constants.userProfileData" (onLogOut)="logOut()"></manage-account>
        </mat-menu> -->

        <span class="score-settings" style="cursor: default;">Health Score <br>
          <span *ngIf="!surveyComplete">

            <!-- <span class="far fa-question-circle fa-stack-2x" style="color:#000; cursor: pointer;"></span> -->
            <span class="fa-stack"  matTooltip="Check your health score now" style="vertical-align: top; cursor: pointer; font-size: 14px;" (click)="redirectToSurvey()">
              <i class="far fa-circle fa-stack-2x" style="color:#797979"></i>
              <i class="fas fa-question fa-stack-1x fa-inverse" style="color:#797979;"></i>
            </span>
          </span>
          <span style="cursor: default;"  *ngIf="surveyComplete">{{userScore}}</span>
            <i style="color:gold; font-size: 20px; padding-left: 15px; cursor: default;" *ngIf="surveyComplete" class="fas fa-trophy"></i>
          </span>
        <!--<input [(ngModel)]="query" name="query" class="w-100 px-2 search" style="height: 32px;" type="text" placeholder="Search username...">
      <span (click)="search()" class="fas fa-search" style="position: absolute; right: 16px; top: 16px;"></span>-->
      </div>
    </div>
    <span style="margin-right:2%;cursor: pointer;display: inline-block;height:100%;position:relative;width:50px;font-weight:500">
      <span style="position: absolute;top:10px;">Logout</span>
      <span style="position:absolute; top:50%; left:45%;font-size:24px;" (click)="logOut()" class="fas fa-sign-out-alt"></span>
    </span>
  </div>
</div>

<div class="logout-popup" *ngIf="logOutPopup">
  <div class="logout-popup-content">
    <i class="fas fa-spinner fa-pulse" aria-hidden="true"></i>
    <h2>Logging out</h2>
  </div>
</div>

<div *ngIf="false" class="row" style="position: fixed; top:70px; height: 75px; width: 100%; margin: auto;">
  <div style="width: 90%; margin:auto; background-color: #2f2f2f; border-color: #4885ed; box-shadow: 3px 3px 5px 6px #ccc; padding: 3px;">
    <!-- <mat-progress-bar mode="determinate" value="60"></mat-progress-bar> -->
    <p style="text-align: center; position: relative; top: 9px; color: #fff;"> Your yet to complete <span (click)= "redirectToTakeSurvey()" style="color: #4885ed; cursor: pointer;"> your profile </span> to improve your score.  <span> <i (click)= "infoTextHide()" style="position: relative; float: right; padding-right: 30px; color: #4885ed;" class="fas fa-times-circle"></i></span> </p>
  </div>

</div>
