<app-side-navigation-bar></app-side-navigation-bar>
<app-sidebar></app-sidebar>
<app-headbar></app-headbar>

<div class="teleconsult-main-container">
 
  <div class="col-12 row">
    <span id="affiliate_image" *ngIf="brand_image_url_exist"><img [src]="brand_image_url"/></span>
    <h3 class="mb-4">{{headerName}}</h3>
  </div>

  <div *ngIf="isLoading" class="loading-screen"><i class="fas fa-spinner fa-pulse" aria-hidden="true"></i></div>
  <div *ngIf="!isLoading">
  <div *ngIf="_constantsService.teleconsultationFlowSelected == 'affiliate';">
    <div class="fa fa-reply element tele-back-btn" (click)="goToAffiliationDashboard()">
      <div style="font-size: 12px; padding-top: 2px;">Back</div>
    </div>
  </div>
  <div class="row" *ngIf="consultationDashboard">
    <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3" (click) = "termsAndConditionAgree('now')">
      <div class = "teleconsult_main_dashboard_card">
        <span class= "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 teleconsult_span_dashboard_card">
          <i class="fas fa-3x fa-video teleconsult_icon_dashboard_card" style="color: #F782AC;"></i>
          <h4 class="teleconsult_header_dashboard_card">Consult Now</h4>
        </span>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3" (click) = "termsAndConditionAgree('book')">
      <div class = "teleconsult_main_dashboard_card">
        <span class= "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 teleconsult_span_dashboard_card">
          <i class="far fa-3x fa-calendar-alt teleconsult_icon_dashboard_card" style="color: #77B35D;"></i>
          <h4 class="teleconsult_header_dashboard_card">Book Appointment</h4>
        </span>
      </div>
    </div> -->

    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3" (click) = "termsAndConditionAgree('now')">
      <div class = "teleconsult_main_dashboard_card">
        <span class= "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 teleconsult_span_dashboard_card">
          <i class="fas fa-3x fa-video teleconsult_icon_dashboard_card" style="color: #F782AC;"></i>
          <h4 class="teleconsult_header_dashboard_card">Consultation</h4>
        </span>
      </div>
    </div>

    <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4" (click) = 'followUp()'>
      <div class = "teleconsult_main_dashboard_card" >
        <span class= "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 teleconsult_span_dashboard_card">
          <i class="fas fa-3x fa-sync-alt teleconsult_icon_dashboard_card" style="color: #FF7802"></i>
          <h4 class="teleconsult_header_dashboard_card">Followup Calls</h4>
        </span>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4" (click) = "summary()">
      <div class = "teleconsult_main_dashboard_card" >
        <span class= "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 teleconsult_span_dashboard_card">
          <i class="far fa-3x fa-file teleconsult_icon_dashboard_card" style="color: #F782AC;"></i>
          <h4 class="teleconsult_header_dashboard_card">My Medical Files</h4>
        </span>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-4" (click) = "selectDoctors()">
      <div class = "teleconsult_main_dashboard_card" >
        <span class= "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 teleconsult_span_dashboard_card">
          <i class="fas fa-3x fa-user-md teleconsult_icon_dashboard_card" style="color: #15AAC0"></i>
          <h4 class="teleconsult_header_dashboard_card">My Consultants</h4>
        </span>
      </div>
    </div> -->

    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3" (click) = "myAppointment()">
      <div class = "teleconsult_main_dashboard_card" >
        <span class= "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 teleconsult_span_dashboard_card">
          <i class="far fa-3x fa-calendar-check teleconsult_icon_dashboard_card"></i>
          <h4 class="teleconsult_header_dashboard_card">My Appointments</h4>
        </span>
      </div>
    </div>

    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3" (click) = "MedicalFiles()">
      <div class = "teleconsult_main_dashboard_card" >
        <span class= "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 teleconsult_span_dashboard_card">
          <i class="far fa-3x fa-file teleconsult_icon_dashboard_card" style="color: #F782AC;"></i>
          <h4 class="teleconsult_header_dashboard_card">My Medical Files</h4>
        </span>
      </div>
    </div>

    <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3" (click) = "MySubscriptions()">
      <div class = "teleconsult_main_dashboard_card" >
        <span class= "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 teleconsult_span_dashboard_card">
          <i class="fas fa-3x fa-bell teleconsult_icon_dashboard_card" style="color: #a5a23f"></i>
          <h4 class="teleconsult_header_dashboard_card">My Subscriptions</h4>
        </span>
      </div>
    </div> -->
    
    <!-- <ng-container *ngIf="consultationPlatformData !== undefined && consultationPlatformData !== null && consultationPlatformData.length > 0">
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3" (click) = "selectSpeciality('Fitness Class')">
        <div class = "teleconsult_main_dashboard_card" >
          <span class= "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 teleconsult_span_dashboard_card">
            <i class="fac fa-3x fa-fitness teleconsult_icon_dashboard_card" style="color: rgb(106, 191, 100);"></i>
            <h4 class="teleconsult_header_dashboard_card"> Wellness Cart </h4>
          </span>
        </div>
      </div>
    </ng-container> -->
  </div>

  <div class="consultationHisDash row" *ngIf="consultationHistoryDashboard">
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-4">
      <div class = "teleconsult_history_card">
        <h4 class="teleconsult_history_title mb-3">Consultation History</h4>
        <div class="teleconsult_history_scroll" (scroll)="onScroll($event)">
          <div class="teleconsult_history_content" *ngFor = "let teleconsultHistory of consultationHistory;  let i = index">
            
            <table>
              <tr (click)="consultationDetails(i)" style="cursor: pointer;">
                
                <td>
                  <i class="fas fa-1x fa-video video_icon" style="color: #F782AC;"></i>
                  <!-- <i class="fas fa-1x fa-video video_icon" style="color: #ff4081a6;" style="visibility: hidden;"></i> -->
                </td>
                
                <td style="width: 150px;word-break: break-word;">
                  <div class="teleconsult_meh_title_color">{{teleconsultHistory.consultant_details.consultant_name}}</div>
                  <div class="teleconsult_li_color">{{teleconsultHistory.consultant_details.specality}}</div>
                </td>

                <td style="white-space: nowrap;">
                  <div class="teleconsult_meh_title_color">
                    {{extractDate(teleconsultHistory.appointment_details.appointment_start_time)}}
                  </div>
                  <div class="teleconsult_li_color">
                    {{extractTime(teleconsultHistory.appointment_details.appointment_start_time)}}
                  </div>
                </td>
                
                <td>
                  <div style="padding-top: 1px;font-size: 12px;">
                    {{callDuration(teleconsultHistory.call_details.call_started_at, teleconsultHistory.call_details.call_ended_at)}}
                  </div>
                </td>
                
              </tr>
            </table>
            
            <div class ="teleconsult_history_line">
              <span class="dot"></span>
              <div class="horizontal-rule"></div> 
              <span class="dot"></span>
            </div>
          </div>

          <div class="teleconsult_history_content_mobile" *ngFor = "let teleconsultHistory of consultationHistory; let i = index;">
            <table>
              <tr (click)="consultationDetails(i)" style="cursor: pointer;">              
                <td>
                  <i class="fas fa-1x fa-video video_icon_mobile" style="color: #ff4081a6;"></i>
                  <!-- <i class="fas fa-1x fa-video video_icon" style="color: #ff4081a6;" style="visibility: hidden;"></i> -->
                </td>
                
                <td>
                  <div class="teleconsult_meh_title_color_mobile" style="word-break: break-word;">{{teleconsultHistory.consultant_details.consultant_name}}</div>
                  <div class="teleconsult_li_color_mobile" style="width: -webkit-fill-available;word-break: break-word;width: -moz-available;width: stretch;">{{teleconsultHistory.consultant_details.specality}}</div>
                  <div class="teleconsult_li_descrip_mobile" style="width: -webkit-fill-available;word-break: break-word;width: -moz-available;width: stretch;">
                    <span class="dot_padding_1" style="padding-right: 5px;">
                      {{extractDate(teleconsultHistory.appointment_details.appointment_start_time)}}
                    </span>
                    <span class="dots" style="padding-right: 5px;"></span>
                    <span class="dot_padding" style="padding-right: 5px;padding-left: 5px;">
                      {{extractTime(teleconsultHistory.appointment_details.appointment_start_time)}}
                    </span>
                    <span class="dots" style="padding-right: 5px;"></span>
                    <span class="dot_padding" style="padding-right: 5px;padding-left: 5px;">
                      {{callDuration(teleconsultHistory.call_details.call_started_at, teleconsultHistory.call_details.call_ended_at)}}
                    </span>
                  </div>
                </td>
                
              </tr>
            </table>
            
            <div class ="teleconsult_history_line_mobile">
              <span class="dot"></span>
              <div class="horizontal-rule"></div> 
              <span class="dot"></span>
            </div>
          </div>
          <div *ngIf="loaderSpinConHistory" class="loading-screen"><i class="fas fa-spinner fa-pulse" aria-hidden="true"></i></div>
          
        </div>
      </div>
    </div>
    
    <div  class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mb-4">
      <!-- <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4" (click) = "termsAndConditionAgree('now')">
          <div class = "teleconsult_history_main_dashboard_card">
            <span class= "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 teleconsult_history_span_dashboard_card">
              <i class="fas fa-3x fa-video teleconsult_history_icon_dashboard_card" style="color: #F782AC;"></i>
              <h4 class="teleconsult_history_header_dashboard_card dashboard_font">Consult Now</h4>
            </span>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4" (click) = "termsAndConditionAgree('book')">
          <div class = "teleconsult_history_main_dashboard_card">
            <span class= "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 teleconsult_history_span_dashboard_card">
              <i class="far fa-3x fa-calendar-alt teleconsult_history_icon_dashboard_card" style="color: #77B35D;"></i>
              <h4 class="teleconsult_history_header_dashboard_card dashboard_font">Book Appointment</h4>
            </span>
          </div>
        </div>
      </div> -->
       <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4" (click) = "termsAndConditionAgree('now')">
          <div class = "teleconsult_history_main_dashboard_card">
            <span class= "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 teleconsult_history_span_dashboard_card">
              <i class="fas fa-3x fa-video teleconsult_history_icon_dashboard_card" style="color: #F782AC;"></i>
              <h4 class="teleconsult_history_header_dashboard_card dashboard_font">Consultation</h4>
            </span>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4" (click) = "myAppointment()">
          <div class = "teleconsult_history_main_dashboard_card" >
            <span class= "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 teleconsult_history_span_dashboard_card">
              <i class="far fa-3x fa-calendar-check teleconsult_history_icon_dashboard_card"></i>
              <h4 class="teleconsult_history_header_dashboard_card dashboard_font">My Appointments</h4>
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4" (click) = "MedicalFiles()">
          <div class = "teleconsult_history_main_dashboard_card" >
            <span class= "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 teleconsult_history_span_dashboard_card">
              <i class="far fa-3x fa-file teleconsult_history_icon_dashboard_card" style="color: #F782AC;"></i>
              <h4 class="teleconsult_history_header_dashboard_card dashboard_font">My Medical Files</h4>
            </span>
          </div>
        </div>

      <!-- <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4" (click) = "summary()">
          <div class = "teleconsult_history_main_dashboard_card" >
            <span class= "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 teleconsult_history_span_dashboard_card">
              <i class="far fa-3x fa-file teleconsult_history_icon_dashboard_card" style="color: #F782AC;"></i>
              <h4 class="teleconsult_history_header_dashboard_card dashboard_font">My Medical Files</h4>
            </span>
          </div>
        </div>

        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4" (click) = "selectDoctors()">
          <div class = "teleconsult_history_main_dashboard_card" >
            <span class= "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 teleconsult_history_span_dashboard_card">
              <i class="fas fa-3x fa-user-md teleconsult_history_icon_dashboard_card" style="color: #15AAC0"></i>
              <h4 class="teleconsult_history_header_dashboard_card dashboard_font">My Consultants</h4>
            </span>
          </div>
        </div>
      </div>

        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4" (click) = 'followUp()'>
          <div class = "teleconsult_history_main_dashboard_card" >
            <span class= "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 teleconsult_history_span_dashboard_card">
              <i class="fas fa-3x fa-sync-alt teleconsult_history_icon_dashboard_card" style="color: #FF7802"></i>
              <h4 class="teleconsult_history_header_dashboard_card dashboard_font">Followup Calls</h4>
            </span>
          </div>
        </div> -->

        <!-- <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4" (click) = 'MySubscriptions()'>
          <div class = "teleconsult_history_main_dashboard_card" >
            <span class= "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 teleconsult_history_span_dashboard_card">
              <i class="fas fa-3x fa-bell teleconsult_history_icon_dashboard_card" style="color: #a5a23f"></i>
              <h4 class="teleconsult_history_header_dashboard_card dashboard_font">My Subscriptions</h4>
            </span>
          </div>
        </div> -->

      </div>

      <!-- <ng-container *ngIf="consultationPlatformData !== undefined && consultationPlatformData !== null && consultationPlatformData.length > 0">
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4" (click) = "selectSpeciality('Fitness Class')">
            <div class = "teleconsult_history_main_dashboard_card" >
              <span class= "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 teleconsult_history_span_dashboard_card">
                <i class="fac fa-3x fa-fitness teleconsult_history_icon_dashboard_card" style="color: rgb(106, 191, 100);"></i>
                <h4 class="teleconsult_history_header_dashboard_card dashboard_font"> Wellness Cart </h4>
              </span>
            </div>
          </div>
        </div>
      </ng-container> -->
    </div>

  </div>

  <div class="row" *ngIf="isUserAndConsultantDataRetrived">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4" style="color: red;text-align: center;margin: auto;">
      There is a problem with connecting to the server.<br>Please try after sometime.
    </div>
  </div>

  </div>
</div>
<!-- <button mat-raised-button color="primary" (click)= "iFrameOpen()" style="left: 100px;">Genix</button> -->
<app-footer></app-footer>

<!-- 
  <i class="fas fa-5x fa-heartbeat" style="color: #F53F00;"></i>
  <i class="fas fa-5x fa-pills" style="color: #2895F0;"></i>
  <i class="fas fa-5x fa-lungs-virus" style="color: #BE4BDC;"></i>
  <i class="fas fa-5x fa-brain" style="color: #FD7E15;"></i>
  <i class="fas fa-5x fa-medkit" style="color: #65BC87;"></i>
  <i class="fas fa-5x fa-bone" style="color: #F782AC;"></i>

  <i class="fas fa-info"></i>
  <i class="fas fa-phone-alt"></i>
  <i class="far fa-calendar-alt"></i>
-->