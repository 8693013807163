<app-side-navigation-bar></app-side-navigation-bar>
<app-sidebar></app-sidebar>
<app-headbar></app-headbar>

<div class="teleconsult-main-container">

  <div class="row my-2" *ngIf="brand_image_url_exist">
    <div class="col row">
      <span id="affiliate_image" *ngIf="brand_image_url_exist"><img [src]="brand_image_url"/></span>
      <h3>{{headerName}}</h3>
    </div>
    <div class="col">
      <span class="fa fa-reply element backButton" (click)="shrinkCard()">
        <div style="font-size: 12px;padding-top: 2px;">Back</div>
      </span>
    </div>
  </div>

  <div class="row my-2" *ngIf="!brand_image_url_exist">
    <div class="col">
      <h3>{{headerName}}</h3>
    </div>
    <div class="col">
      <span class="fa fa-reply element backButton" (click)="shrinkCard()">
        <div style="font-size: 12px;padding-top: 2px;">Back</div>
      </span>
    </div>
  </div>

  <div class="row" *ngIf="classesList == undefined || classesList == null || classesList.length == 0 && !noCourseIsAvailable" >
    <mat-spinner  [diameter]="40" style="margin:auto;margin-top: 100px;color: royalblue;" mode="indeterminate"></mat-spinner>
  </div>

  <div class="row" *ngIf="classesList !== undefined">
    <div *ngIf = "noCourseIsAvailable" style="text-align: center;margin: auto;color: red;">
      No Course Is Available Right Now.
    </div>
    <ng-container *ngFor="let classList of classesList; let i = index">
      <ng-container *ngIf="classList.isCourseAvailable && !noCourseIsAvailable">
        <div #class_card class="col-md-6 col-lg-4 col-xl-4"
          [ngClass]="{'col-md-12 col-lg-12 col-xl-12' : cardExpendDiv == true && class_card.id == selectedCourse.course_id}"
          id="{{classList.course_id}}">
          <div *ngIf="!cardExpendDiv">
            <div class="doctor-card">
              <div class="teleconsult_doctor_list_header">
                <span class="teleconsult_triangle_topleft" [ngStyle]="{'border-top-color':1}">
                  <h6 class="teleconsult_availability">
                    {{classList.course_type}}</h6>
                </span>

                <span *ngIf="this._constantsService.teleconsultationFlowSelected == 'affiliate'" class="exclusive_container">
                  <span class="exclusive_container_span">Exclusive Offer</span>
                </span>
                <!-- <span class="info_btn"><mat-icon style="cursor: pointer;" (click)="expandReviewCard(classList)">info</mat-icon></span> -->
                <div class="teleconsult_img_container">
                  <!-- <img src="{{classList.course_img_url}}" class="teleconsult_profile_image" alt="" /> -->
                  <img [src]="imageConverter(classList.course_img_url)" class="teleconsult_profile_image" alt="">
                </div>
                <!-- <div class="teleconsult_profile_menu_icon">
                  <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                    class="mat_button_outline">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item class="mat_button_outline" *ngIf="!classList.favouriteClass"
                      >
                      <mat-icon>star</mat-icon>
                      <span>Add to favourite</span>
                    </button>
                    <button mat-menu-item class="mat_button_outline" *ngIf="classList.favouriteClass"
                      >
                      <mat-icon style="color: #2895F0;">star</mat-icon>
                      <span>Remove from favourite</span>
                    </button>
                  </mat-menu>
                </div> -->
              </div>

              <div class="teleconsult_doctor_list_body">
                <h6 class="teleconsult_doctor_name">
                  <!-- {{ 'Online Group' }}<br> -->
                  {{ classList.title }}<br>
                </h6>
                <!-- <div style="text-align:center">
                  <span class="week_days" *ngFor="let weekDay of classList.course_on">
                    {{weekDay}}
                  </span>
                </div>
                <div style="margin:8px 0px; text-align:center">
                  <span class="avaTime" *ngFor="let course_time of classList.course_time">
                    {{course_time}}
                  </span>
                </div> -->
                <p class="mb-2" style="text-align: center;"> Host By <span class="teleconsult_doctor_experience"> {{classList.consultant_name}} </span> </p>
                <p *ngIf="this._constantsService.teleconsultationFlowSelected == 'genric'" class="teleconsult_doctor_fees mb-2">&#x20B9; {{(classList.course_fees != '0') ? classList.course_fees+' (Includes all tax)' : classList.course_fees}}</p>
                <p *ngIf="this._constantsService.teleconsultationFlowSelected == 'affiliate'" class = "teleconsult_doctor_fees mb-2">MRP : <s> &#x20B9; {{classList.affilation_excusive_data.affilation_array[0].affilation_mrp}}</s>{{' | '}}Offer Price: &#x20B9; {{classList.affilation_excusive_data.affilation_array[0].affilation_price}}</p>
                <p class="teleconsult_doctor_fees mb-2">{{'Fees For: '}} {{classList.fees_for}}</p>

                <!-- <div class="star_unchecked mb-1">
                  <span *ngFor="let stars of getStars(classList.overall_review.avg_review)">
                    <i class="fa {{stars.star}}" > </i>
                  </span>
                </div>
                <p class="teleconsult_doctor_fees mb-2">{{classList.overall_review.review_count}} reviews</p>
                <span></span> -->
                <!-- <p class="teleconsult_doctor_fees mb-2"><b>Course Days:</b></p>
                <p class="teleconsult_doctor_fees mb-2">{{classList.course_on.join(", ")}}</p> -->
              </div>
              <div class="teleconsult_doctor_list_footer">
                <button class="mb-2" (click)="expandReviewCard(classList)" class="subscribe_button">
                  View Details
                </button>
              </div>
            </div>
          </div>

          <div class="cardExpendContainer" *ngIf="cardExpendDiv && class_card.id == selectedCourse.course_id">
            <div [ngClass]="{'cardExpendFullDiv row': class_card.id == selectedCourse.course_id}">
              <div
                [ngClass]="{'col-md-12 col-lg-4 col-xl-4 p-0': class_card.id == selectedCourse.course_id , 'doctor-card': class_card.id !== selectedCourse.course_id}">
                <div class="doctor-card cardShadowRemove">
                  <div class="teleconsult_doctor_list_header">
                    <span class="teleconsult_triangle_topleft">
                      <h6 class="teleconsult_availability">{{selectedCourse.course_type}}</h6>
                    </span>
                    <div class="teleconsult_img_container">
                      <!-- <img src="{{selectedCourse.course_img_url}}" class="teleconsult_profile_image" alt="" /> -->
                      <img [src]="imageConverter(selectedCourse.course_img_url)" class="teleconsult_profile_image" alt="">
                    </div>
                    <!-- <div class="teleconsult_profile_menu_icon teleconsult_profile_menu_icon_pos_top">
                      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                        class="mat_button_outline">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item class="mat_button_outline" *ngIf="!classList.favouriteDoctor"
                          >
                          <mat-icon>star</mat-icon>
                          <span>Add to favourite</span>
                        </button>
                        <button mat-menu-item class="mat_button_outline" *ngIf="classList.favouriteDoctor"
                          >
                          <mat-icon style="color: #2895F0;">star</mat-icon>
                          <span>Remove from favourite</span>
                        </button>
                      </mat-menu>
                    </div> -->
                  </div>


                  <div class="teleconsult_doctor_list_body">
                    <h6 class="teleconsult_doctor_name">
                      <!-- {{ 'Online Group' }}<br> -->
                      {{ classList.title }}<br>
                    </h6>
                    <!-- <div style="text-align:center">
                      <span class="week_days" *ngFor="let weekDay of selectedCourse.course_on">
                        {{weekDay}}
                      </span>
                    </div>
                    <div style="margin:8px 0px; text-align:center">
                      <span class="avaTime" *ngFor="let course_time of selectedCourse.course_time">
                        {{course_time}}
                      </span>
                    </div> -->
                    <!-- <p class="teleconsult_doctor_experience mb-2">{{classList.provider}}</p> -->
                    <p class="mb-2" style="text-align: center;"> Host By <span class="teleconsult_doctor_experience"> {{classList.consultant_name}} </span> </p>

                    <p *ngIf="this._constantsService.teleconsultationFlowSelected == 'genric'" class="teleconsult_doctor_fees mb-2">&#x20B9; {{(classList.course_fees != '0') ? classList.course_fees+' (Includes all tax)' : classList.course_fees}}</p>
                    <p *ngIf="this._constantsService.teleconsultationFlowSelected == 'affiliate'" class = "teleconsult_doctor_fees mb-2">MRP : <s> &#x20B9; {{classList.affilation_excusive_data.affilation_array[0].affilation_mrp}}</s>{{' | '}}Offer Price: &#x20B9; {{classList.affilation_excusive_data.affilation_array[0].affilation_price}}</p>
                    <p class="teleconsult_doctor_fees mb-2">{{'Fees For: '}} {{classList.fees_for}}</p>
                    <!-- <div class="star_unchecked mb-1">
                      <span *ngFor="let stars of getStars(classList.overall_review.avg_review)">
                        <i class="fa {{stars.star}}" > </i>
                      </span>
                    </div>
                    <p class="teleconsult_doctor_fees mb-2">{{classList.overall_review.review_count}} reviews</p> -->
                    <p class="teleconsult_doctor_fees mb-2"><b>Course Days:</b></p>
                    <p class="teleconsult_doctor_fees mb-2">{{dayRearrange(classList.course_on)}}</p>
                  </div>
                  <div class="teleconsult_doctor_list_footer" *ngIf="isCourseDetailCardOpen">
                    <p *ngIf="autoApproveCourseStatus">Course status : Ongoing Course</p>
                    <mat-spinner *ngIf="!showSubscribeBtn || !isValidationDone" [diameter]="40" style="margin:auto;color: royalblue;" mode="indeterminate"></mat-spinner>
                    <button *ngIf="showSubscribeBtn && !selectedCourse.subscribed && !selectedCourse.isCourseExpired && (!selectedCourse.isOnGoing || selectedCourse.auto_approve) && isValidationDone" class="mb-2" (click)="expandCard(classList)" class="subscribe_button">
                      Subscribe
                    </button>
                    <!-- <button *ngIf="classList.subscribed && !classList.isCourseExpired" class="mb-2" class="subscribe_button" style="cursor:not-allowed; background-color: rgb(44 176 57);">
                      Active Subscribe
                    </button> -->
                    <p class = "review_skip" *ngIf="showSubscribeBtn && selectedCourse.subscribed && isValidationDone" (click) = "moveToMySubscription()">Already Subscribed</p>
                    <button *ngIf="showSubscribeBtn && selectedCourse.isCourseExpired && !selectedCourse.subscribed && !selectedCourse.isOnGoing && isValidationDone" class="mb-2" class="subscribe_button" style="cursor:not-allowed; background-color: gray;">Expired</button>
                    <button *ngIf="showSubscribeBtn && selectedCourse.isOnGoing && !selectedCourse.auto_approve && !selectedCourse.isCourseExpired && !selectedCourse.subscribed && isValidationDone" class="mb-2" class="subscribe_button" style="cursor:not-allowed; background-color: gray;">Ongoing Course</button>
                    <!-- <p class="review_skip" *ngIf="!isValidationDone"><i class="fas fa-spinner fa-pulse" aria-hidden="true"></i></p> -->
                  </div>

                </div>

              </div>
              <div *ngIf="class_card.id == selectedCourse.course_id && isBookingCardOpen"
                class="col-md-12 col-lg-4 col-xl-4 bookAppointmnetSecondCardContainer">
                <h6 class="teleconsult_doctor_name">
                  <i class="fa-2x far fa-clock appointmentTitleIcon"></i>
                  Select Start time & date
                </h6>
                <div class="horizontal-rule"> </div>
                <div *ngIf="selectedCourseTimeSlots != undefined">
                  <div class="horizontal-rule"> </div>
                  <div class="timeSlot">
                    <div class="morningSlot">
                      <p class="timeSlotTitle"> <i class="fas fa-cloud-sun timeSlotTitleIcon"></i> Morning</p>
                      <div class="row" style="margin: auto;" *ngIf="selectedCourseTimeSlots['morning'].length != 0">
                        <div class="avaTime"
                        (click)="selectTimeSlot(slot)"
                        *ngFor="let slot of selectedCourseTimeSlots['morning']"
                        [ngClass]="{'selectAvaTime':selectedTimeSlot == slot}"> {{slot}} </div>
                      </div>
                      <div class="row" *ngIf="selectedCourseTimeSlots['morning'].length == 0">
                        <div style="text-align: center;color: gray;margin: auto;">No slots available</div>
                      </div>
                    </div>
                    <div class="horizontal-rule"> </div>
                    <div class="afternoonSlot">
                      <p class="timeSlotTitle"> <i class="fas fa-sun timeSlotTitleIcon"></i> Afternoon</p>
                      <div class="row" style="margin: auto;" *ngIf="selectedCourseTimeSlots['afternoon'].length != 0">
                        <div class="avaTime"
                        (click)="selectTimeSlot(slot)"
                        *ngFor="let slot of selectedCourseTimeSlots['afternoon']"
                        [ngClass]="{'selectAvaTime':selectedTimeSlot == slot}"> {{slot}} </div>
                      </div>
                      <div class="row" *ngIf="selectedCourseTimeSlots['afternoon'].length == 0">
                        <div style="text-align: center;color: gray;margin: auto;">No slots available</div>
                      </div>
                    </div>
                    <div class="horizontal-rule"> </div>
                    <div class="eveningSlot">
                      <p class="timeSlotTitle"> <i class="fas fa-cloud-sun timeSlotTitleIcon"></i> Evening</p>
                      <div class="row" style="margin: auto;" *ngIf="selectedCourseTimeSlots['evening'].length != 0">
                        <div class="avaTime"
                        (click)="selectTimeSlot(slot)"
                        *ngFor="let slot of selectedCourseTimeSlots['evening']"
                        [ngClass]="{'selectAvaTime':selectedTimeSlot == slot}"> {{slot}} </div>
                      </div>
                      <div class="row" *ngIf="selectedCourseTimeSlots['evening'].length == 0">
                        <div style="text-align: center;color: gray;margin: auto;">No slots available</div>
                      </div>
                    </div>
                    <div class="horizontal-rule"> </div>
                    <div class="eveningSlot">
                      <p class="timeSlotTitle"> <i class="fas fa-cloud-sun timeSlotTitleIcon"></i> Night</p>
                      <div class="row" style="margin: auto;" *ngIf="selectedCourseTimeSlots['night'].length != 0">
                        <div class="avaTime"
                        (click)="selectTimeSlot(slot)"
                        *ngFor="let slot of selectedCourseTimeSlots['night']"
                        [ngClass]="{'selectAvaTime':selectedTimeSlot == slot}"> {{slot}} </div>
                      </div>
                      <div class="row" *ngIf="selectedCourseTimeSlots['night'].length == 0">
                        <div style="text-align: center;color: gray;margin: auto;">No slots available</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="class_card.id == selectedCourse.course_id && isBookingCardOpen"
                class="col-md-12 col-lg-4 col-xl-4 bookAppointmnetSecondCardContainer">

                <!-- Do not add maxDate, that may affect some functions -->
                <!-- Refer to updateCalendarCss function in subscribe-online-classes.component.ts -->
                <mat-calendar #matCalendar
                (selectedChange)="dateChanged($event)"
                [minDate]="selectedCourse.minStartDate"
                [dateClass]="dateClass()"
                [(selected)]="selectedCourse.minStartDate"
                [dateFilter]="filterDates"></mat-calendar>

                <div class="text-center">
                  <button [hidden]="isLoading" *ngIf="!selectedCourse.subscribed && !selectedCourse.isCourseExpired" class="confirm_subscription_button" style="cursor: pointer;" (click)="confirmSubscription()">
                    Confirm Subscription
                  </button>
                  <span *ngIf="isLoading" class="confirm_subscription_button" style="font-size: 20px;"><i class="fas fa-spinner fa-pulse" aria-hidden="true"></i></span>
                  <!-- <p style="margin: 10px auto; color: rgb(40,149,240);">
                    <a style="border-bottom: 1px solid; text-decoration: none; cursor: pointer;" (click)="refundInfo()">
                      <mat-icon style="position: absolute; margin-left: -25px;">error</mat-icon>
                      Click to read the terms & conditions
                    </a>
                  </p> -->
                </div>
              </div>

              <div *ngIf="class_card.id == selectedCourse.course_id && isCourseDetailCardOpen" class="col-md-12 col-lg-4 col-xl-4 bookAppointmnetSecondCardContainer">
                <h6 class="teleconsult_doctor_name" style="float:left">
                <i class="fa-2x fas fa-book-open appointmentTitleIcon"></i>
                  Course Description
                </h6>
                <div class="profileTxt">
                  <p *ngIf="selectedCourse.course_description != ''" [innerHTML]="finalUrlLink"></p>
                  <!-- <p *ngIf="selectedCourse.course_description != ''">{{selectedCourse.course_description}}</p> old code(without redirecting link) -->
                  <p *ngIf="selectedCourse.course_description == ''">No description added</p>
                </div>
              </div>

              <div *ngIf="class_card.id == selectedCourse.course_id && isCourseDetailCardOpen" class="col-md-12 col-lg-4 col-xl-4 lastCardDiv">
            <h6 class = "teleconsult_doctor_name" style="float:left" >
              <i class="fa-2x fas fa-star appointmentTitleIcon"></i>
              Reviews
            </h6>
            <div class = "profileTxt" [ngClass]="{'subscribed':selectedCourse.subscribed, 'not_subscribed':!selectedCourse.subscribed}">
              <p style="color: #4d4d4d;font-weight: 500;" *ngIf="selectedCourse.text_reviews_data.length == 0 || selectedCourse.text_reviews_data == undefined">No Reviews...</p>
              <div *ngIf =" selectedCourse.text_reviews_data.length > 0">
                <div *ngFor="let eachReview of newDateOrderReviews(selectedCourse.text_reviews_data)">
                  <div style="font-size: 11px;">
                    <p style ="padding-right: 5px;color: #4d4d4d;font-weight: bold;display: inline-block;font-size: 13px;margin-bottom: 5px;">
                      {{eachReview.user_name}}
                    </p>
                    <span class="fa fa-star" [ngClass]="{'stars_color' : eachReview.user_rating < 1, 'review_star_check' : eachReview.user_rating  > 0}"></span>
                    <span class="fa fa-star" [ngClass]="{'stars_color' : eachReview.user_rating < 2, 'review_star_check' : eachReview.user_rating  > 1}"></span>
                    <span class="fa fa-star" [ngClass]="{'stars_color' : eachReview.user_rating < 3, 'review_star_check' : eachReview.user_rating  > 2}"></span>
                    <span class="fa fa-star" [ngClass]="{'stars_color' : eachReview.user_rating < 4, 'review_star_check' : eachReview.user_rating  > 3}"></span>
                    <span class="fa fa-star" [ngClass]="{'stars_color' : eachReview.user_rating < 5, 'review_star_check' : eachReview.user_rating  > 4}"></span>
                  </div>
                  <!-- <br> -->
                  <p style="color: #4d4d4d;font-weight: 500;">{{eachReview.rating_text}}</p>
                </div>
              </div>
              <div *ngIf ="(selectedCourse.text_reviews_data.length == 0 || selectedCourse.text_reviews_data == undefined) && classList.subscribed && isValidationDone">
                <div style="font-size: 11px;float: left;">
                  <span class="fa fa-star stars_color"></span>
                  <span class="fa fa-star stars_color"></span>
                  <span class="fa fa-star stars_color"></span>
                  <span class="fa fa-star stars_color"></span>
                  <span class="fa fa-star stars_color"></span>
                </div>
                <br>
                <p>Enter Your Reviews in below text field</p>
              </div>
            </div>

            <div class= "review_box" *ngIf="selectedCourse.subscribed && isValidationDone">
              <div class="mb-1">
                <i class="fa fa-star" [ngClass]="{'stars_color' : rateArray[0] == 0, 'selectedLiveStar' : rateArray[0] == 1}" (click)="countStars(1)"></i>
                <i class="fa fa-star" [ngClass]="{'stars_color' : rateArray[1] == 0, 'selectedLiveStar' : rateArray[1] == 1}" (click)="countStars(2)"></i>
                <i class="fa fa-star" [ngClass]="{'stars_color' : rateArray[2] == 0, 'selectedLiveStar' : rateArray[2] == 1}" (click)="countStars(3)"></i>
                <i class="fa fa-star" [ngClass]="{'stars_color' : rateArray[3] == 0, 'selectedLiveStar' : rateArray[3] == 1}" (click)="countStars(4)"></i>
                <i class="fa fa-star" [ngClass]="{'stars_color': rateArray[4] == 0, 'selectedLiveStar' : rateArray[4] == 1}" (click)="countStars(5)"></i>
              </div>
              <mat-form-field appearance="outline" class = "rating_inp">
                <input matInput class = "rating_letters" placeholder="Your review on {{selectedCourse.name}}" #reviewField>
                <mat-icon matSuffix class = "rating_icon" style="cursor: pointer;" *ngIf="!sendReview" (click)="feedBack(selectedCourse)">send</mat-icon>
                <mat-spinner matSuffix [diameter]="30"  *ngIf="sendReview"></mat-spinner>
              </mat-form-field>
            </div>
          </div>

            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

</div>

<app-footer></app-footer>


<!----------------------------------------------Download Invoice------------------------->
<div class="text-dark" id="invoicePdf" #invoicePdf [ngStyle]="{'display': showInvoice ? 'block' : 'none'}">
  <div class="row">
    <div class="col-2 text-right">
      <img src="./assets/img/ihl-plus.png" class="h-100">
    </div>
    <div class="col-3" style="border-left:1px solid lightgrey">
      <span style="color:rgb(40, 149, 240);">INDIA</span>
      <br>
      <span style="color:gray;">HEALTH</span>
      <br>
      <span style="color:rgb(40, 149, 240);">LINK</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12" >
      <span style="float: right;">
        Contact : +91 80-47485152
        <br>
        Email : info@indiahealthlink.com
        <br>
        Web : indiahealthlink.com
        <br>
        Address : SCO #394, New Gain Market
        <br>
        Haryana, India.
      </span>
    </div>
  </div>

  <div class="row my-2">
    <div class="col-4"></div>
    <div class="col-4 text-center" style="font-weight: bold;"><b> Payment Receipt</b></div>
  </div>
  <hr>
  <div class="row my-30">
    <div class="col-6">
      <span>
        <b>Name:</b> {{userName}}
        <br>
        <b>Phone Number:</b> {{userMobNumber}}
        <br>
        <b>Email:</b> {{userMail}}
        <br>
        <b>Address: </b> <span [innerHTML]="userAddress"></span>
      </span>
    </div>
    <div  class="col-6" style="float:right;">
      <span>
        <b>Duration:</b> {{course_duration}}
        <br>
        <b>GST Number:</b> {{'06AADCI2816A1Z7'}}
        <br>
        <b>Organization:</b> {{provider}}
      </span>
    </div>
  </div>
  <hr>
  <div  class="row">
    <span class="col-6"><b>Item Description</b></span>
    <span class="col-3"><b>Payment Method</b></span>
    <span class="col-3"><b>Amount (Rs)</b></span>
  </div>
  <hr>
  <div *ngIf="couponDiscountAmount > 0" class="row">
    <span class="col-6">
      {{' '}}
    </span>
    <span class="col-3">Subscription Fees</span>
    <div class="col-3" style="text-align: right; right: 100px"> {{course_fees.toFixed(2)}} </div>
  </div>
  <div *ngIf="couponDiscountAmount > 0" class="row">
    <span class="col-6">
      {{' '}}
    </span>
    <span class="col-3">Coupon</span>
    <div class="col-3" style="text-align: right; right: 100px"> - {{couponDiscountAmount.toFixed(2)}} </div>
  </div>
  <div  class="row">
    <span class="col-6">
      "{{title}}"  Online class is subscribed
      <br>
      Couse duration {{ course_duration}}
    </span>
    <span class="col-3">Net Amount</span>
    <div class="col-3" style="text-align: right; right: 100px"> {{deductedIgstAmt}} </div>
  </div>
  <div  class="row" *ngIf="state != 'haryana'">
    <span class="col-6">
      {{' '}}
    </span>
    <span class="col-3">IGST@ 18%</span>
    <div class="col-3" style="text-align: right; right: 100px"> {{igstAmt}} </div>
  </div>
  <div  class="row" *ngIf="state == 'haryana'">
    <span class="col-6">
      {{' '}}
    </span>
    <span class="col-3">SGST@ 9%</span>
    <div class="col-3" style="text-align: right; right: 100px"> {{sgstAmt}} </div>
  </div>
  <div  class="row" *ngIf="state == 'haryana'">
    <span class="col-6">
      {{' '}}
    </span>
    <span class="col-3">CGST@ 9%</span>
    <div class="col-3" style="text-align: right; right: 100px"> {{sgstAmt}} </div>
  </div>
  <div  class="row">
    <span class="col-6">
      {{' '}}
    </span>
    <span class="col-3">Total (incl. of all taxes)</span>
    <div class="col-3" style="text-align: right; right: 100px"> {{totalAmount.toFixed(2)}} </div>
  </div>
  <hr>
  <div  class="row">
    <span class="col-12">
      Note- This is an electronic receipt (Rupees in INR)
    </span>
  </div>
</div>