<app-side-navigation-bar></app-side-navigation-bar>
<app-sidebar></app-sidebar>
<app-headbar></app-headbar>
<div id="policy">
   <section id="disclaimer" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <mat-card>
         <mat-card-title>Disclaimer</mat-card-title>
         <mat-card-content>
            The IHL kiosk is intended to be used by individuals of 18 years or older, individuals under 18 years can check the weight and BMI as guest user under direct supervision of guardian. IHL user portal can be used by individuals of 18 years or older. IHL does not give medical advice. The IHL service may provide helpful information about you, such as vitals. The information and materials available through the IHL service are for informational and educational purposes only and not intended to constitute professional advice, diagnosis or treatment, or to substitute for your judgment. The Body Data and other information in your account may not always be accurate, complete, or up-to-date and should be used for informational purposes only. You assume full risk and responsibility for the use of information you obtain from or through the IHL Service. In addition, we do not recommend or endorse any provider of health care or health-related products, items, or services.
         </mat-card-content>
      </mat-card>
   </section>
   <section id="grievance" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <mat-card>
         <mat-card-title>Grievance</mat-card-title>
         <mat-card-content>
            (Effective 04/10/2017)<br><br>
            1. This policy applies to all Grievances arising out of the use of any of the services of India Health Link Pvt. Ltd., a body corporate registered in India with its Registered Office at SCO-394, New Grain Market, Karnal, 132001 (herein after referred to as the ‘Company’)and applies to all its services delivered through its portal viz: www.indiahealthlink.com and the IHL Kiosks operating at different locations in India.
            <br><br>
            2. The official currently designated as a Grievance Redressal officer (herein after called the GRO) for the purpose of Grievance Redressal is
            <br><br>
            Name: Ramakanta Behera, (CCO)
            <br><br>
            E Mail: ramakanta@indiahealthlink.com
            <br><br>
            3. Every grievance that any person (complainant) reports to GRO shall identify the complainant and the nature of the complaint clearly
            <br><br>
            4. The grievance report as received will be automatically documented with a unique ID and acknowledged.
            <br><br>
            5. The GRO will make a preliminary assessment of the registered grievance and identify if it is in the nature of a technical error or requires resolution under Section 79 of Information Technology Act 2000. (ITA 2000)
            <br><br>
            6. Grievances of technical nature will be responded by the GRO in consultation with the technical team.
            <br><br>
            7. Where the grievance is assessed as a notice under Section 79 of ITA 2000, the GRO shall initiate necessary remedial action within 3 working days of receipt of the notice.
            <br><br>
            8. Where the notice is not in conformity with the requirements of ITA 2000 the company reserves the right to respond within such other reasonable time as it may deem fit.
            <br><br>
            9. Where the notice is based on the contractual agreement between the complainant and the Company, the GRO will proceed as per the terms of the agreement.
            <br><br>
            10. No grievance will be registered unless the person making the complaint completes the grievance report form including his full name, Physical address and E Mail address.
            <br><br>
            11. On receipt of the reference, the GRO shall take necessary steps including collecting the response from other affected persons and arrive at a suitable decision based on the documentary evidence presented before him/her.
            <br><br>
            12. In the event the parties to the grievance indicate that are not satisfied with the resolution of the GRO, the GRO shall forward the complaint along with the details of resolution arrived at to designated appeal authority
            <br><br>
            13. In the event the parties to the grievance are not satisfied with the decision of the appeal authority, the matter may be referred to "Arbitration" through an arbitration facility either in Delhi or through an appropriate Online Dispute Resolution Mechanism under the provisions of Arbitration and Conciliation Act 1996 subject to laws in India.
            <br><br>
            14. Any grievance arising between an user and any party other than the Company, but arising out of the services rendered by the Company shall be resolved by them independent of the Company subject to the following.
            <br><br>
            a. The users may in the absence of any contractual agreement to the contrary, are deemed to agree to use the arbitration process suggested for resolution of grievance between the members and the Company.
            <br><br>
            b. Where the members are not satisfied with the decision of the arbitration or where the dispute is subject to mandatory adjudication by a competent judicial body, the appropriate judicial authority having jurisdiction in Delhi or such other jurisdiction to which they mutually agree to shall be approached by the parties for resolution.
            <br><br>
            c. The role of the Company in such dispute resolution process is limited to providing assistance to the grievance resolution authority by way of providing information that may be available with the Company which may be required for the resolution.
         </mat-card-content>
      </mat-card>
   </section>
   <section id="terms" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <mat-card>
         <mat-card-title>Terms & Conditions</mat-card-title>
         <mat-card-content>
            Effective (04/10/17)<br><br>
            The following represents the terms and conditions applicable to all the services rendered by India Health Link Pvt Ltd, (IHL), a body corporate registered in India with its Registered Office at SCO-394, New Grain Market, Karnal, 132001 (herein after referred to as the ‘Company’) and delivered through its Portal viz: www.indiahealthlink.com (herein after referred to as the ‘portal’)and the IHL Kiosks operating at different locations in India.
            <br><br>
            The Company reserves the right to modify this policy as and when required and this is the current version modified last on 04/10/17.
            <br><br>
            This document read along with the associated Privacy Policy constitutes a contractual obligation between the user of the service and IHL as per laws applicable in India.
            <br><br>
            Kindly go through these terms before proceeding to use any of the services offered on the Portal including the information available for browsing.
            <br><br>
            In the event any user is not agreeable to any of the terms of this document, they shall kindly refrain from continuing to use the services. Continuation of the use of the services is a confirmation of the user’s consent for valuable consideration received to provide their personal information under the terms mentioned here in.
            <br><br>
            This consent shall be irrevocable for the period for which the user continues to use the services, subject further to the data retention requirements as mentioned here in.
            <br><br>
            The User (which term shall apply to any person who uses any of the services of IHL either through the Portal or the Kiosk) agrees that a Copy of this document along with the Terms will be sent by the company in confirmation through the registered e-mail provided by the user if any.
            <br><br>
            Without waiting for the receipt of such e-mail, User confirms that he has chosen to provide confirmation, by clicking on the button “I AGREE” at the end of this document.
            <br><br>
            Where As
            <br><br>
            IHL owns and maintains the Portal www.indiahealthlink.com and provides certain information there in for browsing and offers certain services through the Portal and
            <br><br>
            IHL also owns and maintains, kiosks in several physical locations in India which enables users of the Kiosk to undergo certain health checks along with a facility to also upload the results directly to the Portal under a membership service,
            <br><br>
            Where As
            <br><br>
            The User is interested in using any or all of the services offered through the Portal and the Kiosk under the terms under which they are offered and
            <br><br>
            The user declares that he/she is an individual who is legally competent to enter into contracts as per laws prevailing in India and propose to avail any or all of the services provided by IHL through this Portal
            <br><br>
            User and IHL agree that this document constitutes a valid agreement between them setting down the terms under which the services will be provided by IHL and used by the user.
            <br><br>
            Definitions<br>
            1. In interpreting these terms, Following definitions apply
            <br><br>
            a. “Portal” means www.indiahealthlink.com and includes subdomains if any there under
            <br><br>
            b. “Visitor” means a person who is visiting this Portal and going through its contents
            <br><br>
            c. “User” means a person who uses the services offered and includes the visitor who browses through the Portal and those who register and use any additional services.
            <br><br>
            d. “Company” means India Health link Pvt Ltd, a registered company having its office at SCO-394, New Grain Market, Karnal, Haryana 132001 and includes their legal representatives, executors, administrations and assigns.
            <br><br>
            e. “De-identified Information” means any information that with the associated parameters does not identify the information as belonging to any specific individual.
            <br><br>
            f. “Limited Data Set” means any otherwise de-identified information that may contain demographic information associated with the individual to whom the information may belong and is required for Research.
            <br><br>
            g. “Kiosk” means device used to measure vitals such as Weight, BMI, BMC, Pulse and ECG. It is hardware / software platform which can print the reports, show advertisements, do telecommunications for tele-medicine and diagnosis etc.
            <br><br>
            h. “Telemedicine Service” means the exchange of medical information and data, remote treatment and diagnosis of patients through telecommunications and electronic technology.
            <br><br>
            2. Any person or organization that uses the services of this Portal and or the Kiosk declares that he is competent to enter into contractual agreements as per the laws of his country, has not been barred from entering into contracts with the Company for any reason and to abide by the laws prevailing in India.
            <br><br>
            3. Applicable Laws and Jurisdiction<br>
            The Company is registered in India and this agreement is deemed to be executed within the jurisdictional bounds of India. Any disputes arising out of this agreement shall be subject to the jurisdiction of Courts in Delhi , India as per laws applicable in India.
            <br><br>
            Status under Indian Law<br>
            4. This Portal provides services to enable users to store their health records which may be easily accessed by them at times of need. The function of this Portal and the Kiosk is limited to providing a platform that can be used by the different users. The Portal does not initiate transmission of messages or select or modifies the information contained in the transmission of any information except with the intervention of the user and is therefore considered an “Intermediary” as per Information Technology Act 2000.
            <br><br>
            5. The Kiosk is a device which is offered as a tool for the user to check certain health parameters and optionally upload the results to the member’s information maintained on the Portal. Kiosk measures the health parameters based on technology which is considered reasonably reliable and transmits the information to the Company’s storage systems keeping the local storage requirements to only operational contingencies. The Kiosk is an electronic system consisting of hardware and software which is leased out to the user temporarily at the time of its use and the user either picks up the information at the Kiosk and leaves or decides to trigger the health data storage service of the Company by sending the information from the Kiosk to the Company.
            <br><br>
            6. Telemedicine Service is a service where the users may consult registered medical practitioners sharing with them the health records. The Company is not in the business of providing medical advise by itself. The role of the Company in this service is limited to arranging the appointment through the electronic system and making reasonable background verification of the medical practitioners whose contacts appear on the Portal. The user is responsible to make his own enquiries about any of the medical practitioners that he wishes to consult and satisfy himself about the appropriateness of the person for his needs.
            <br><br>
            User’s Obligations<br>
            7. The user shall provide accurate and complete information about himself including e-mail address at the time of registering himself for the use of the service and shall inform the Company of any changes that may occur subsequently. Any notice sent to the registered e-mail address shall be considered as an effective delivery of the notice to the user. Any notices to be sent to the Company in respect of any of the reasons related to the use of the Portal shall be sent to the address provided by the Company in the Grievance Policy document available on the Portal.
            <br><br>
            8. Any information collected by the Company is collected, processed and stored subject to the “Privacy policy” in accordance with the best practices in the industry and in accordance with the laws prevailing in India.
            <br><br>
            9. The user shall refrain from posting any information that constitutes violation of any of the provisions of Indian laws. In particular, the user shall not host, display, upload, modify, publish, transmit, update or share any information that —
            <br><br>
            (a) belongs to another person and to which the user does not have any right to;
            <br><br>
            (b) is grossly harmful, harassing, blasphemous defamatory, obscene, pornographic, pedophilic, libelous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;
            <br><br>
            (c) harm minors in any way;
            <br><br>
            (d) infringes any patent, trademark, copyright or other proprietary rights;
            <br><br>
            (e) violates any law for the time being in force;
            <br><br>
            (f) deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;
            <br><br>
            (g) impersonate another person;
            <br><br>
            (h) contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource;
            <br><br>
            (i) threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation
            <br><br>
            10. The User shall not carry out any activity which unauthorizedly damages, modifies or deletes any content on the Portal or the functionality of the application or otherwise impairs the functioning of the Portal whether or not such activity provides any wrongful gain to the user or wrongful loss to any other person.
            <br><br>
            11. User shall keep his account particulars confidential and take all steps that are reasonable and necessary to prevent his identity from being compromised.
            <br><br>
            12. User understands and acknowledges that if the user cancels his account or the account is terminated, all account information , including saved records will be deleted from the company’s databases. The user is advised to hold backup copies to safeguard himself from any loss of information due to such deletion.
            <br><br>
            Such Information may however continue to be retained offline for audit purpose and as per data retention requirements as per laws applicable in India.
            <br><br>
            13. User agrees to immediately notify the Company of any unauthorized use of the user account, Profile, or passwords.
            <br><br>
            Privacy Policy<br>
            14. The Company does not use or disclose content on the Portal except as authorized in this agreement or as required by law and in accordance with the Privacy Policy of the Company which is considered part of this terms.
            <br><br>
            Additional Terms Applicable to Medical Practitioners<br>
            15. The Medical practitioner is responsible for maintaining the confidentiality of the information shared by the customers during the consultation process. He shall not download the health data of the customers from the Portal nor receive any access password from the customers even if offered.
            <br><br>
            16. If the medical practitioner requires submission of any health records, he may request and receive it directly from the customer or with the customer authorizing the Company to send the requested information.
            <br><br>
            Grievance Redressal<br>
            17. In conformity with the requirements of the provisions of Section 79 of Information Technology Act 2000, any disputes arising out of the use of IHL services is subject to the Grievance Redressal procedure indicated on the Portal and the laws applicable in India. Limited Warranty.
            <br><br>
            18. The Company takes all reasonable care to ensure that the Portal and its applications shall be up and running without unreasonable disruption of the service and is free of malicious codes and bugs. However the services are provided on “as is” basis without any representations or warranties express or implied and shall not be liable on account of disruption of service or bugs or other damages that may be caused to the user for reasons beyond the reasonable control of the Company.
            <br><br>
            19. The Company shall take reasonable care to ensure that the content provided by the Company on the Portal is
            <br><br>
            a. Fair, reasonably free of errors and not false or misleading
            <br><br>
            b. Is free from bugs, viruses, Trojans or other malicious codes
            <br><br>
            The Company shall not be liable for any loss including consequential loss of revenue, income, profits or anticipated savings, loss of contracts or business relationships, loss of reputation or goodwill or loss or corruption of information or data or loss in any other form to the user as a result of using the Portal or its applications.
            <br><br>
            Third party Content<br>
            20. The Portal may contain links to third parties including advertisements. The Company shall not be responsible for any damages, claims or liability from your use of any third party Portal you may visit as a result of such links or advertisement content Indemnity.
            <br><br>
            21. The user hereby holds the Company and any of its officers indemnified against any losses, damages, costs, liabilities and expenses including, without limitation legal expenses and any amounts paid by the Company to a third party in settlement of a claim or dispute incurred or suffered by the Company arising out of any breach by the user of any provision of these terms and conditions, or arising out of any claim that the user has breached any provision of these terms and conditions.
            <br><br>
            Cross Border Data Transmission<br>
            22. The Company maintains its data storage facilities in India. However, it reserves the right to host data elsewhere for meeting exigencies or for disaster recovery and business continuity purposes. User by conforming to these terms is deemed to have given his consent for collection, processing and transmission of data as per company’s policy including permission for cross border transfer of data from the user’s country.
            <br><br>
            23. The Company reserves its right to use De-identified information for research and own use.
            <br><br>
            24. The Company reserves its right to use Limited Data sets for research and statistical purposes.
            <br><br>
            Information Security<br>
            25. The Company follows reasonable security practices to protect the loss, misuse or unauthorized alteration of user’s personal information that is made available to the Company by the user.
            <br><br>
            26. Company considers data which is encrypted and where the encryption keys are securely held separately as adequately secured information to which the Privacy and other security Controls as applicable to unencrypted personal or sensitive personal information are not applicable.
            <br><br>
            Changes<br>
            27. The above terms and the Privacy policy are subject to changes from time to time and such changes will be notified on the Portal. It shall be the responsibility of the user to verify the latest version of the terms each time he makes use of the service.
            <br><br>
            Termination<br>
            28. The Company reserves the right to terminate the registration of any of the user solely at its discretion without assigning any reason thereof.
            <br><br>
            29. The obligations under this agreement shall subsist for a period of one year after the member ceases to be a member Data Retention
            <br><br>
            30. As per requirements of the laws in India, the Company reserves the right to archive any of the data related to the user or content posted by him at any time during the tenure of his use for a reasonable period even after the user terminates the use of the application.
            <br><br>
            31. When the user opts to withdraw any content posted by him earlier, such deleted content may be preserved in back up copies for a reasonable period or archived by the Company though they are removed from the public view.
            <br><br>
            32. Any content posted on the Portal may be made available on demand by a law enforcement authority under a due process of law.
            <br><br>
            33. The company shall not however is obligated to keep back up of any content and provide the content on demand to any user Severability
            <br><br>
            34. If any term of this Agreement is held by a court of competent jurisdiction to be invalid or unenforceable, then this Agreement, including all of the remaining terms, will remain in full force and effect as if such invalid or unenforceable term had never been included.
            <br><br>
            <mat-card-title>Teleconsultation Terms & Conditions</mat-card-title>
            <p> IHL Teleconsultation (services run by India Health Link Private Limited) is providing online consultations through its digital platform provided by Genix Technologies Private Limited. IHL collaborates with  clinical experts of various specializations to provide health consultation via video conferencing (“Tele-Consultation”) through its website and through health KIOSKs. The following terms and conditions govern all the transactions with respect to tele-consultations including appointment booking, refund, and all other transactions through the website or through its authorized third party websites. </p>
            <br>
            <p>The IHL service available on the Website do not in any way constitute an invitation or recommendation to avail the tele-consultation services. The services provided through the website or KIOSK are not intended in any manner to be a substitute in person consultation with a doctor or any healthcare professional. IHL advises the users to make independent assessment in respect of its accuracy or usefulness and suitability prior to making any decision in reliance hereof. Terms 'you' and 'your' hereafter refers to the user both registered and unregistered who is using/browsing through the IHL website (www.indiahealthlink.com) and/or using KIOSKs deployed at various facilities of our partners or clients </p>
            <br>
            <div>
            <div>
               1. &nbsp; Availability, Appointment, Rescheduling, Follow-up, and/or Cancellation :
            </div>
            <div>
               <ol class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-3">
                  <li>
                     <P> Tele-consultation is available for only users living in India only as per the latest tele-consultation guideline released by Govt of India </P>
                  </li>
                  <li>
                     <P> Tele-consultations shall be available for any clinical consultations aligned to the tele-medicine notification by government of India on tele-medicine. </P>
                  </li>
                  <li>
                     <P> Tele-consultation from a specific doctor/physician shall be available subject to his/her availability/willingness/discretion. Confirmation of your appointment with a specific doctor/physician,as per his/her availability, shall be sent to you via SMS and/or via email and/or via webportal. IHLreserves the right to reschedule or cancel an appointment without any prior notice though the company will communicate the cancellation or reschedule information via SMS and/or email and/or webportal.</P>
                  </li>
                  <li>
                     <P> The time provided for consultation to you is indicative. The actual consultation time may change depending on the consulting Doctor's discretion.</P>
                  </li>
                  <li>
                     <P> Your consulting Physician reserves the right to provide post consultation follow-up at no additional cost. </P>
                  </li>
                  <li>
                     <P> Consultations can be booked, rescheduled or canceled only within the stipulated time limits mentioned below: </P>
                  </li>
                  <li>
                     <P> Cancellation & Rescheduling Deadlines </P>
                  </li>
                  <ul class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-2">
                     <li>
                        <P>Schedule an appointment <span class="font-italic">(With in book next 1 hour to next 5 days) </span> : </P>
                     </li>
                     <ul class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-2">
                        <li>
                           <P>1 Hour before approved / confirmed appointment time where 70% of the total fee will be refunded </P>
                        </li>
                        <li>
                           <P> Cancellation available only till 1 hour after the scheduled booking, otherwise it will be considered as no show case and there will be no refund in no show cases </P>
                        </li>
                        <li>
                           <P> The rescheduling and cancellation will only be accepted through the website. </P>
                        </li>
                     </ul>
                     <li>
                        <P> Consult Now <span class="font-italic">(With in next 15 to 45 Mins) </span> </P>
                     </li>
                     <ul>
                        <li>
                           <P> 1 Hour before approved / confirmed appointment time where 70% of the total fee will be refunded </P>
                        </li>
                        <li>
                           <P> There will be a 50% fee refund for any cancellation. </P>
                        </li>
                        <li>
                           <P> 30 min after the scheduled appointment will be considered as no show case and no refund will be issued. </P>
                        </li>
                     </ul>
                  </ul>
               </ol>
            </div>
            </div>
            <div>
            <div> 
              2. &nbsp; Listing of Doctor or Practitioner :
            </div>
            <div>
              <ol class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-3">
                <li> <p>IHL provides value added “Telehealth Services' ' which connects users directly to the doctor and the information exchanged between the user and the practitioner is recorded, stored, and used in accordance with the relevant Privacy Policy. However, it is at the discretion of the user to avail the service. If a user has used the telehealth service, IHL reserves the right to share the contact details of the user with the concerned practitioner.</p> </li>
                <li> <P> Without prejudice to the generality of the service, IHL has no role to play in determining the consultation fees of the doctors. The consultation fees are determined at the discretion of the doctors. IHL will charge the doctors consultation fee along with a convenience fee and taxes payable at the time of appointment with the patient. </P> </li>
                <li> <P> Without any prejudice to the generality of the doctors rating and reviews, IHL has no role in increasing doctors rating or providing favorable or otherwise feedback against any doctor. IHL does not ensure or take liability of any relation between the rating feedback and the quality of the services provided by the doctors. Users are responsible to make their own judgment while selecting any doctor for theservices </P> </li>
              </ol>
            </div>
            </div>
            <div>
            <div>
              3. &nbsp; Information and Documents required  :
            </div>
            <div>
              <ol class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-3">
                <li> <P> You are solely responsible for the medical, health and personal information you provide in India Health Link health portal and during the consultation. The advice of the doctor will depend upon the information you provide to the doctor. </P> </li>
                <li> <P> User discretion is advised while submitting/uploading your required personal and clinical information on the specified place and selecting the doctor for seeking appropriate advice related to the condition of the patient. This information shall include patient’s details including contact details, medical history, testing/investigations results/reports, prescriptions, card holder name and other relevant details. </P> </li>
                <li> <P> IHL tele-consultation services are available for use for children, but the registered member for all patients under the age of 18 must be the patient’s parent or legal guardian. If you register as the parent or legal guardian on behalf of a minor, you will be fully responsible for complying with these Terms of Use. </P> </li>    
                <li> <P> If the information provided by you is false/deceptive/inaccurate or misleading, IHL reserves the right to cancel your appointment without assuming any liability whatsoever. Hence, it’s advised to submit/upload all relevant and accurate information on the portal or while sharing information during the consultation. </P> </li>
                <li> <P> The information provided by you may be used for the purpose of services including analysis, research, training and disclosure (where required) to its affiliates, agents, others,and can be shared on demand with government authorities on request or in emergency situations </P> </li>
                <li> <P> The information provided by you can be kept with IHL  indefinitely and can be used without revealing your identity. </P> </li>               
              </ol>                    
            </div>
            </div>
            <div>
            <div>
              4. &nbsp; Equipment and connectivity requirement  :
            </div>
            <div>
              <ol class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-3">
                <li> <P> The services available on the website shall be provided to you via audio/video consultation. Please ensure that you are equipped with proper video conference equipment and internet connectivity as mentioned or is relevant for the service you want to avail. In case of inadequate video conference equipment, technical error or defective internet connectivity IHL reserves the right to reschedule or cancel your appointment without any notice. </P> </li>
                <li> <P> You understand and acknowledge that there will be no physical examination involved and the consultation services shall be provided remotely. The opinion delivered by the doctor / physician shall solely be based on the verbal communication between the concerned doctor or the physician and the patient, and the test reports, and other information provided/uploaded by you on the website. </P> </li>
                <li> <P> IHL or the consultingoctor/medical practitioner shall not be liable for misdiagnosis/faulty judgment/interpretation error/perception error/adverse events/inefficacy of prescribed treatment or advice/validity provided by the consulting doctor in your country of residence/unavailability of the recommended or prescribed treatment or medication under any condition or circumstances. Users are advised to use their discretion for following the advice obtained post consultation from IHL. </P> </li>   
                <li> <P> IHL Services are not meant in any way for emergency and life-threatening conditions. It is advised to take the patient to the nearest hospital in such cases. </P> </li>        
              </ol>                    
            </div>
          </div>
         
          <div>                
            <div>
              5. &nbsp; Cookies   :
            </div>
            <div>
              <ol class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-3">
                <li> <P> IHL, like many other websites, uses “Cookies”. Cookies are small data files that a Website stores on your web browser. These are used for the purpose of storing your preferences, browsing activities, profiling and tracking behavior on this website. By visiting IHL website you acknowledge, accept, and expressly authorize IHL for the placement of Cookies on your web browser. </P> </li>                     
              </ol>                    
            </div>
          </div>
         
          <div>                
            <div>
              6. &nbsp; Electronic Payments/ Refunds   :
            </div>
            <div>
              <ol class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-3">
                <li> <P> IHL offer’s online mode of payment. You may use internet banking or debit/credit card or specific digital payment modes available  for making payment for consultation charges. You shall be directed to our trusted payment gateway partners once you proceed to make payment of service fee. </P> </li>   
                <li> <P> IHL shall not be liable in any manner whatsoever, if incorrect or inaccurate credit / debit card details or digital payment details are provided by you for making the payment of consultation fee or you use a credit/ debit card which is not lawfully owned by you or if you permit a third party to use your password or other means to access your account. </P> </li>
                <li> <P> IHL shall keep the aforesaid information confidential at all times and shall not utilize and share the same with any of the third parties unless it is an authorized third party website and/or required by law/regulation or court order. </P> </li>
                <li> <P> Refunds may be made in case a request is raised by the user by clicking the cancel button in the user dashboard on the IHL website within the stipulated deadline mentioned in the refund & cancellation table above. </P> </li>
                <li> <P> Refunds will only be made against genuine and trackable cancellation requests generated by you by clicking on the cancellation button provided in the IHL user dashboard in your IHL account. Refund request against delays, nature of advice obtained, efficacy of treatment, health outcomes of consultation will not be entertained. </P> </li>
                <li> <P> Refunds may be processed within 15 working days from the receipt of a request from you. All communications with regards to refund should be sent to info@indiahealthlink.com. </P> </li>
                <li> <P> You have the option to reschedule or cancel a booked appointment, strictly within the stipulated deadlines as mentioned in the refunds and cancellations table above. </P> </li>
                <li> <P> IHL reserves the right to modify/implement a new pricing structure at any time prior to billing you for your initial payment or for future payments. </P> </li>                    
              </ol>                    
            </div>
          </div>
         
          <div>                
            <div>
              7. &nbsp; Use of Content & Copyright   :
            </div>
            <div>
              <ol class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-3">
                <li> <P> IHL owns the website as well the intellectual property contained thereon including all programs, processes, designs, software, technologies, trademark, trade names, inventions and materials therein and all the services offered by the doctors/physicians or others on the website. </P> </li>   
                <li> <P> You shall not use the content available on the website without the prior written permission of IHL. </P> </li>                             
              </ol>                    
            </div>
          </div>
         
          <div>                
            <div>
              8. &nbsp; Privacy and Security   :
            </div>
            <div>
              <ol class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-3">
                <li> <P> IHL uses secure encryption technology to keep your information safe and secure at all times. IHL is committed towards maintaining the privacy of the information uploaded by you on the website and complies with the industry standard security safeguards for the purpose of securing the website and the information provided/uploaded by you. </P> </li>  
                <li> <P> IHL uses all reasonable endeavors to ensure the integrity of the online payment facility’s security. Despite our endeavors, breaches of security and confidentiality could occur. You acknowledge that we are not liable for any loss suffered by you as a result of any breaches in security. </P></li>                              
              </ol>                    
            </div>
          </div>
         
          <div>                
            <div>
              9. &nbsp; Value added services from associated partners   :
            </div>
            <div>
              <ol class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-3">
                <li> <P>IHL engages various health care service providers to provide you additional convenience. You are free to select any product or services you need if that fits your needs. </P></li>    
                <li> <P>During the course of any service if you may come across any promotional services and you will be prompted to agree or disagree to avail the services supplied by the Associated Vendors by providing your explicit consent. </P></li> 
                <li> <P> Upon your acceptance and willingness to avail associated vendor services, IHL reserves the right to share your entire prescription, personal details to the vendor to contact you for fulfillment of services. And you agree to provide the rights to the associated service vendor to contact you on the provided contact details mobile number or email address or both. </P> </li>
                <li> <P> It must be noted that IHL is a mere facilitator to connect you to the goods or service fulfillment partners  and shall not be liable for any deficiency or defect in services supplied by the Associated Vendors. The Services facilitated by the client shall be in accordance with the terms of use that you agree with the vendor. </P> </li>                           
              </ol>                    
            </div>
          </div>
       
          <div>                
            <div>
              10. &nbsp; Indemnity    :
            </div>
            <div>
              <ol class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-3">
                <li> <P> You agree and undertake to indemnify and keep indemnified IHL , the concerned doctor/physician and IHL for any losses, costs, charges and expenses including reasonable attorney fees that the concerned doctor/physician and IHL may suffer on account of (a)misdiagnosis/faulty judgment/interpretation errors/perception error arising from (i)your failure to provide correct and/or complete clinical information/history about the patient in timely and clinically appropriate manner; or (ii)suppression of material facts; or your failure to provide relevant clinical information about the patient; or (iv)misinterpretation of the advice/prescription/diagnosis by you; or (v)failure to follow doctor’s advice/prescription by you; or (b)incorrect or inaccurate credit/debit card details provided by you; or (c)using a credit / debit card which is not lawfully owned by you; or (d)if you permit a third party to use your password or other means to access your account. </P> </li>                            
              </ol>                    
            </div>
          </div>
         
          <div>                
            <div>
              11. &nbsp; Disclaimer   :
            </div>
            <div>
              <ol class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-3">
                <li> <P> IHL provides the services on “as is” basis and expressly disclaims all representations, warranties or conditions of any kind, either expressed or implied, as to the operations of the website, the information, content, service provided through or in connection with the website. </P></li>       
                <li> <P> In no event shall IHL be responsible for any third party misuse for the data and for any acts, deeds and circumstances beyond the control of the management of IHL. </P> </li>
                <li> <P>You agree and acknowledge that you are solely responsible for your conduct. </P></li>   
              </ol>                    
            </div>
          </div>
         
          <div>                
            <div>
              12. &nbsp; Limitations of liability  :
            </div>
            <div>
              <ol class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-3">
                <li> <P> In no event shall IHL  be liable for any direct, indirect, punitive, incidental, special or consequential damages or any damages whatsoever including without limitations, damages for loss of use, data or profits, arising out of or in any way connected with the use of IHL services. </P></li>         
              </ol>                    
            </div>
          </div>
         
          <div>                
            <div>
              13. &nbsp; Termination  :
            </div>
            <div>
              <ol class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-3">
                <li> <P> This arrangement may be terminated by either party at any time, with or without cause. </P> </li>        
                <li> <P> IHL reserves the right to refuse the use of services immediately in case your conduct is deemed by IHL  to be in contravention of applicable acts, laws, rules, and regulations or considered to be unethical/immoral. </P> </li>
                <li> <P> IHL reserves the right to take down your profile as well as the right to access your profile in case IHL finds feedback from doctors of any unlawful conducts, abusive languages, and misconduct during the consultation process display, with or without notice to the concerned practitioner. </P> </li>
              </ol>                    
            </div>
          </div>
         
          <div>                
            <div>
              14. &nbsp; Modification of Terms and Conditions of Service  :
            </div>
            <div>
              <ol class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-3">
                <li> <P> IHL may at any time, without any prior notification to you, modify these terms of conditions. </P> </li>         
                <li> <P> Please review the latest version of the terms and conditions before proceeding to avail the service. </P> </li>
                <li> <P> If you continue to use the service, it shall be deemed that you agree and abide by the modified terms and conditions. </P> </li>
              </ol>                    
            </div>
          </div>
         
          <div>                
            <div>
              15. &nbsp; General Terms and Conditions  :
            </div>
            <div>
              <ol class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-3">
                <li> <P> This agreement shall be governed by the laws of India and shall be subject to the exclusive jurisdiction of the courts at New Delhi and </P> </li>       
                <li> <P> IHL makes no representation that the services are appropriate for patients in any other location except India as IHL operates and controls these websites from India. </P> </li>
                <li> <P> If any part of the agreement is determined to be invalid or unenforceable pursuant to applicable law then the said provision will be superseded by a valid, enforceable provision and the remainder of the agreement shall continue in effect. </P> </li>
                <li> <P> You acknowledge that the relationship created through your use of this website shall be of principal to principal. You shall not represent yourself as a representative agent or employee of IHL. </P> </li>
                <li> <P> You represent and warrant that you’re not a minor (i.e., you are above 18 years of age) and that you are competent and eligible to enter into a legally binding agreement. </P> </li>
              </ol>                    
            </div>
          </div>
          <div> The terms and conditions herein form an agreement. Please read this agreement carefully and if you are not agreeable to any terms and conditions please do not proceed further. </div>
          <div> By clicking on “I Agree” and proceeding further you signify that you read through, understood and accepted aforementioned terms and conditions of use of service. You must agree to the above terms and conditions in order to avail the services through the website. If you have any queries or need any clarification or assistance please contact us at <b> info@indiahealthlink.com </b> </div>
          <br>
          <div>"<b>Confidentiality Notice: </b> This document is confidential and contains proprietary information and intellectual property of India Health Link Pvt Ltd. This document is only meant to be referred by specific users to whom it is shared by India Health Link Pvt Ltd. Neither this document nor any of the information contained herein may be reproduced or disclosed under any circumstances without the express written permission of India Health Link Pvt Ltd. Please be aware that disclosure, copying, distribution or use of this document and the information contained therein is strictly prohibited."‌</div>
         </mat-card-content>
      </mat-card>
   </section>
   <section id="privacy" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <mat-card>
         <mat-card-title>Privacy Policy of India Health Link Pvt Ltd </mat-card-title>
         <mat-card-content>
            (Effective from 04/10/2017)<br>
            This Privacy Policy represents the Privacy Commitment of India Health Link Pvt Ltd., a body corporate registered in India with its Registered Office at SCO-394, New Grain Market, Karnal, 132001 (herein after referred to as the ‘Company’)and applies to all its services delivered through its portal viz: www.indiahealthlink.com and the IHL Kiosks operating at different locations in India.
            <br><br>
            The Company reserves the right to modify this policy as and when required and this is the current version modified last on 04/10/2017.
            <br><br>
            This Privacy Policy shall be read along with the Terms of Use applicable as applicable and apply for all persons who use the services of the company. (herein after referred to as the ‘user’)
            <br><br>
            1. Our Commitment<br>
            IHL recognizes that “Privacy” is a key right of an individual who interacts with the Company either for browsing through this Portal or for availing any of the services rendered here in including the IHL Kiosks in physical locations.
            <br><br>
            In the context of the services rendered by the Company, “Privacy” is recognized as a “Right of an individual to Control how information related to a person is collected and used by the Company”.
            <br><br>
            As a Company registered in India, IHL follows the principles of Personal and Sensitive Personal information protection as mandated by the Information Technology Act 2000 as amended from time to time (ITA 2000).
            <br><br>
            Being in compliance with ITA 2000, the Company is also in conformity with the broad principles of Privacy protection that are internationally recognized such as the HIPAA-HITECH Act of USA, though the Company specifically declares that its obligations and liabilities are restricted to ITA 2000 as the law applicable in India and not any other international law.
            <br><br>
            2. Consent<br>
            By opting to use the services of the Company, users are deemed to have provided their express consent to provide such personal data as they may share with the Company, in exchange of the services rendered or promised to be rendered by the Company as indicated here in and further detailed under the specific terms associated with the services and/or under FAQ section in the Portal.
            <br><br>
            The user agrees to receive transactional or promotional emails / SMS  / push notifications in registered email address or mobile number  This consent shall be irrevocable for the period for which the user continues to use the services.
            <br><br>
            In the event any user is not agreeable to any of the terms of this document, they shall kindly refrain from continuing to use the services. Continuation of the use of the services is a confirmation of the user’s consent for valuable consideration received to provide their personal information under the terms mentioned here in.
            <br><br>
            This consent shall be irrevocable for the period for which the user continues to use the services, subject further to the data retention requirements as mentioned here in.
            <br><br>
            The User agrees that a Copy of this document along with the Terms will be sent by the company in confirmation through the registered e-mail provided by the user if any.
            <br><br>
            Without waiting for the receipt of such e-mail, User confirms that he has chosen to provide confirmation, by clicking on the button “I AGREE” at the end of this document.
            <br><br>
            3. Information Collected<br>
            Kiosk Service<br>
            IHL kiosks enable users to check certain health parameters here in after referred to as ‘vitals’ such as the weight, Blood Pressure, ECG readings etc. The Kiosk is meant to be used for educational purpose and not as a replacement of consulting a qualified medical practitioner.
            <br><br>
            The Kiosks are intended to be used only by persons aged above 18 years and competent to enter into valid contracts as per law. Others may use the service as “Guests” under the supervision of accompanying guardians.
            <br><br>
            The Kiosk has been calibrated to provide reasonably accurate measurements of the vitals however, given the technical nature of the measurements; the results may not always be completely accurate.
            <br><br>
            Information collected at the Kiosk may at the discretion of the user discarded immediately or forwarded securely to the user’s mobile or his account with IHL on the portal.
            <br><br>
            Portal Browsing<br>
            (a) When any user visits the Portal of IHL, certain technical information about the user is identified and recorded by the Company in order to assist in enhancing the user experience and for administration purpose.
            <br><br>
            Such information includes,
            <br><br>
            i. The details of the device used
            <br><br>
            ii. The Browser and application used by the user,
            <br><br>
            iii. The different pages visited by the user on the Portal, the time spent, interactive actions etc
            <br><br>
            iv. IP address, locational information and Referral page from which he arrived on the Portal
            <br><br>
            v. Time spent by the User on a webpage
            <br><br>
            vi. Type of data uploaded and downloaded etc
            <br><br>
            b) When a user registers as a member of the services offered by the Portal and Kiosk additional information is provided by the User which may include the following:
            <br><br>
            i. Personal information such as Name, Address, Gender, Phone/Mobile number, E Mail address, location, Date of Birth, details of the health records etc.
            <br><br>
            ii. Nature of Services to be used
            <br><br>
            iii. Aadhar Number
            <br><br>
            iv. Suggested User name for registration and chosen password
            <br><br>
            (c) When a user uses the Patient Health Record storage service,
            <br><br>
            i) Health information which user choses to submit for storage and use at a later time including
            <br><br>
            a) information collected at the Kiosk and forwarded for storage on the web
            <br><br>
            b) Information otherwise uploaded in the form of documents such as diagnostic reports, medical reports etc
            <br><br>
            ii) Any other relevant information submitted for availing specific services
            <br><br>
            4. Cookies:<br>
            Company uses cookies to make it easier for you to navigate our site on repeated visits. However the cookies do not contain any personal information.
            <br><br>
            5. How the Information is used<br>
            i) IHL follows the principle of requesting only such minimal information that is required for providing the required service and use it only for the purpose of providing the service for which the information is provided.
            <br><br>
            ii) Information provided by the user is classified into appropriate categories such as “Personal”, “Sensitive Personal” and “other” information and stored either in the local systems or on the cloud.
            <br><br>
            iii) Where feasible, identifiable information is de-identified removing the parameters that link the health data to the individual’s identity parameters.
            <br><br>
            iv) The information submitted by the user is meant for access by the user himself for which access is provided to the user using reasonable access control mechanisms such as “Password” with or without additional authentication mechanisms such as a confirmation code sent through mobile or e-mail.
            <br><br>
            v) Where the user avails “Telemedicine” or “Diagnostic” services, information collected will be shared with the service provider to enable the service to be provided.
            <br><br>
            vi) The Company may use “De-Identified” data or “Limited Data Set” for research and statistical purposes including for Census and Market analysis by Pharma Industry with appropriate reasonable precautions to ensure that the data remains separate from the identity of the individuals as per standard industry practice.
            <br><br>
            vii) The User hereby agrees that the Company may at its discretion chose such partners as they deem fit for the required service and where a service is provided in association with a Business Associate/Contractor/sub-contractor, the Company may share information with such partners with due contractual bindings to ensure that the User gets the required quality of service
            <br><br>
            viii) The Company does not use any identifiable information collected from the user for marketing purposes of third parties unless a separate specific consent has been obtained for the purpose as applicable to the given service.
            <br><br>
            ix) The E-Mail ID of the users may be used for sending communications from the Company regarding any of the services of the Company including security related alerts or one time passwords.
            <br><br>
            x) The phone/mobile of the user may be used for sending security related alerts including one time passwords or other messages which require a direct interaction with the user.
            <br><br>
            xi) On specific occasions, Company may with specific consent of the user, use the e-mail ID of the user for sending messages for marketing of Company’s own services/products.
            <br><br>
            6. How the Information is Shared<br>
            i. User’s information stored with the Company is not generally accessed by any of the employees of the Company. Where required, information is shared within the company strictly on a “Need to know basis”
            <br><br>
            ii. Information may be shared with medical personnel for rendering “Telemedicine services”
            <br><br>
            iii. Information may be shared with law enforcement or other regulatory authorities as per requirements under law for which suitable policies and procedures are set in place within the Company.
            <br><br>
            iv. Under emergent circumstances, information may be shared with the required agencies or authorities after due approval from a medical personnel or an administrator under a defined process with documentation of the reasons for such sharing.
            <br><br>
            7. How the Information is Secured<br>
            i. IHL adopts Reasonable Security Practices to prevent unauthorized access, modification, or denial of access as defined under ITA 2000 to ensure security of the personal and sensitive personal information provided by the User after an informed consent.
            <br><br>
            ii. Accuracy of the Personal information collected from the User is maintained by providing an access to the User to the profile information to facilitate verification of the information and for making corrections as may be necessary from time to time.
            <br><br>
            iii. IHL has taken reasonable care to prevent any malicious codes or viruses affecting the confidentiality, integrity and availability information and adopts such security practices that are considered necessary for maintaining the data security as per legal requirements in force.
            <br><br>
            iv. In case any hyperlinks are provided on the Portal, the users are expected to use discretion before clicking on the links and ensure that they check the Privacy and Security policies of the destination site before using their services.
            <br><br>
            v. IHL has taken reasonable care for Disaster recovery and Business Continuity as is relevant for the nature and size of its activities.
            <br><br>
            vi. Sensitive Personal information is secured during transmission and storage with appropriate and reasonable encryption to mitigate the risk of unauthorized access.
            <br><br>
            vii. The Company ensures through appropriate contractual bindings that whenever the user’s information is accessed or processed by an entity other than the Company, the associates or contractors including their sub contractors if any always adhere to the same security standards that the Company itself tries to provide in facilities within their direct control.
            <br><br>
            viii. The users having direct access to the information stored in their membership area, shall take such suitable steps as are required to ensure that they do not compromise the security of the data in any manner either because of the security deficiencies in their access devices or the networking services.
            <br><br>
            8. Transfer of Information :<br>
            The personal information collected from the users is not transferred to any agency unless such agency is also contractually bound to follow adequate security standards as required under Indian law.
            <br><br>
            All reasonable efforts are taken to ensure that Sensitive personal information, is stored within the boundaries of India even while it is stored on the cloud.
            <br><br>
            9. Retention of Information<br>
            Information collected from the users is retained in a secure manner for a reasonable period subject to the requirements under Indian law.
            <br><br>
            Information not in active use is archived or destroyed as per the information security policy followed by the Company in conformity with the data retention regulations as per laws in India.
            <br><br>
            Any request from the Users for erasure of their personal data will be subject to the data protection laws applicable in India.
            <br><br>
            10. External Links<br>
            This privacy policy is limited to the information provided to the Company when the user uses the services on the Portal or the Kiosk.
            <br><br>
            The Portal may however also contain hyper links to other external Portals. Such sites may have their own privacy policies and users are required to peruse and take note of such policies.
            <br><br>
            11. Grievance Redressal<br>
            i. The Company follows a Grievance Redressal Policy to address any complaints that may arise on account of this Privacy Policy.
            <br><br>
            ii. All disputes are resolved as per laws in force in India and subject to jurisdictional limits in India as defined in the Grievance Redressal policy.
            <br><br>
            iii. Following is the Grievance redressal officer who will receive Privacy related complaints of the Users if any and redress the grievance as per a structured Grievance redressal policy adopted by the Portal
            <br><br>
            Name: Ramakanta Behera, (CCO)
            <br><br>
            E Mail: ramakanta@indiahealthlink.com
            <br><br>
            12. Opt-In Principle<br>
            The Privacy policy followed by the Company is entirely dictated by the choice of the user and we endeavour to provide opt-in choice to the users to the extent technically considered feasible.
            <br><br>
            We believe that protection of Privacy is a joint responsibility of the Company and the user and we encourage users to request for any information that they consider relevant to the protection of their privacy from the designated Privacy Official of the Company.
            <br><br>
            When in doubt about any aspect of this Policy, Users may contact the Company for clarification as required.
            <br><br>
            13. Changes to the Privacy Policy<br>
            Company reserves the right, to change, alter, modify, add, or remove portions from this Privacy Policy at any time and hence Users should review the Privacy Policy from time to time and exercise their option to discontinue the service if they so desire.
            <br><br>
         </mat-card-content>
      </mat-card>
   </section>
   <section id="refund" class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <mat-card>
         <mat-card-title>Refund Policy </mat-card-title>
         <mat-card-content>
            (Effective from 04/10/2017)<br>
            This Privacy Policy represents the Privacy Commitment of India Health Link Pvt Ltd., a body corporate registered in India with its Registered Office at SCO-394, New Grain Market, Karnal, 132001 (herein after referred to as the ‘Company’)and applies to all its services delivered through its portal viz: www.indiahealthlink.com and the IHL Kiosks operating at different locations in India.
            <br><br>
            Our focus is complete customer satisfaction.
            <br><br>
            If you have made a payment via our kiosk and it has been found that an overpayment has been made or in case of a failed health test (amount paid but results not displayed), we will issue a refund to the original account used to make the initial payment.
            <br><br>
            In case of other disputes or discrepancies arising out of the payment on transactions done through our kiosk, please contact us via info@indiahealthlink.com; we will reverse the charges deducted for the transaction within 7 working days, provided the reasons are genuine and proved after investigation.
            <br><br>
            Dispute requests received later than 2 business days after the transaction will be treated as invalid requests.
         </mat-card-content>
      </mat-card>
   </section>
</div>
<app-footer></app-footer>