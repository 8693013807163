<ng-container *ngIf="router && router.url === '/medical-doc'; else elseBlock">
  <app-side-navigation-bar></app-side-navigation-bar>
  <app-sidebar></app-sidebar>
  <app-headbar></app-headbar>

  <div class="teleconsult-main-container">

    <div class="col-12 row">
      <h3 class="mb-4 text-wrap">Medical Document Update</h3>
    </div>
    <div id="back" class="fa fa-reply element tele-back-btn global_back_button_container" (click)="showTeleDashboard()">
      <div class="global_back_button">Back</div>
    </div>

    <div class="row">
      <div class="col-md-4 col-lg-4 col-xl-4 ">
        <ng-container *ngIf="isDeleteProgress">
          <mat-progress-bar mode="indeterminate"  class="progress_bar" style="height: 4px;z-index: 100;"></mat-progress-bar>
        </ng-container>
        <div [ngClass]="{'selected-files': true, 'disable-container': isDeleteProgress}" >
          <div class = "teleconsult_doctor_list_common_header" >
            <h5 class="list_files_header">Select a file
              <ng-container *ngIf="enableMenuOptions">
                <button *ngIf="enableView" type="button" class="close" (click)="onViewClick()" aria-label="Close" class="list_files_header_options">
                  <!-- <i class="fas fa-ellipsis-v"></i> -->
                  <i class="far fa-eye"></i>
                </button>
                <button type="button" class="close" (click)="onUploadClick()" aria-label="Close" class="list_files_header_options">
                  <i class="fa fa-upload"></i>
                </button>
              </ng-container>
            </h5>
          </div>
          <div class="file-list border" >
            <div  class="file_list_container">
              <!-- <ng-container *ngIf="!updateAppointment && _constant.medicalDocumentsList.length > 0; else noFilesBlock">
                <span *ngFor="let documnt of _constant.medicalDocumentsList; let i= index; trackBy: trackByFn;" (click)="onDocumentSelectionClick(documnt, i)" [ngClass]="{'file_list_span': true, 'activeClass': (this.activeDocumentId == documnt.document_id)}" >
                  <img class="pic" src="{{getImageSrc(documnt)}}" alt="icon" style="width: 50px;" ><br>
                  <small [innerHtml] = "documnt.document_name"></small>
                </span>
              </ng-container> -->
              <ng-container *ngIf="_constant.medicalDocumentsList.length > 0; else noFilesBlock">
                <span *ngFor="let documnt of _constant.medicalDocumentsList; let i= index; trackBy: trackByFn;" [ngClass]="{'file_list_span': true}" >
                  <div class="round">
                    <input type="checkbox" id="checkbox_{{documnt.document_id}}" [checked]="documnt.is_checked" (click)="onDocumentSelection(documnt, i, $event)" />
                    <label for="checkbox_{{documnt.document_id}}"></label>
                  </div>
                  <img class="pic" src="{{getImageSrc(documnt)}}" alt="icon" style="width: 50px;" ><br>
                  <small [innerHtml] = "documnt.document_name"></small>
                </span>
              </ng-container>
              <ng-template #noFilesBlock>
                <p class ="no_files" *ngIf="isViewAllDocumentsResponseReceived">Documents not available</p>
                <p class ="no_files" *ngIf="!isViewAllDocumentsResponseReceived" style="top: 150px;">
                  <mat-spinner matSuffix [diameter]="30" style="margin: auto;display: block;"> </mat-spinner>
                  Loading documents.Please wait...
                </p>
              </ng-template>
            </div>
          </div>
        </div>

      </div> 

      <div class="col-md-4 col-lg-4 col-xl-4 ">
        <div [ngClass]="{'selected-files': true, 'disable-container': isDeleteProgress}" >
          <div class = "teleconsult_doctor_list_common_header" >
            <h5 class="list_files_header">Shared Document
              <ng-container *ngIf="enableShareDocMenuOptions">
                <button type="button" class="close" (click)="onSharedDocViewClick()" aria-label="Close" class="list_files_header_options">
                  <!-- <i class="fas fa-ellipsis-v"></i> -->
                  <i class="far fa-eye"></i>
                </button>
              </ng-container>
            </h5>
          </div>
          <div class="file-list border" >
            <div  class="file_list_container">
              <ng-container *ngIf="sharedDocumentsList.length > 0; else noFilesBlock">
                <span *ngFor="let documnt of sharedDocumentsList; let i= index; trackBy: trackByFn;" (click)="onSharedDocumentSelectionClick(documnt, i)" [ngClass]="{'file_list_span': true, 'sharedDocActiveClass': (this.sharedActiveDocumentId == documnt.document_id)}" >
                  <img class="pic" src="{{getImageSrc(documnt)}}" alt="icon" style="width: 50px;" ><br>
                  <small [innerHtml] = "documnt.document_name"></small>
                </span>
              </ng-container>
              <ng-template #noFilesBlock>
                <p class ="no_files" *ngIf="isViewAllDocumentsResponseReceived">Documents not available</p>
                <p class ="no_files" *ngIf="!isViewAllDocumentsResponseReceived" style="top: 150px;">
                  <mat-spinner matSuffix [diameter]="30" style="margin: auto;display: block;"> </mat-spinner>
                  Loading documents.Please wait...
                </p>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 col-lg-4 col-xl-4 ">
        <div class="dropZone" appDnd (onFileDropped)="uploadFile($event)" *ngIf="!uploadStarted; else uploadingBlock">
          <input hidden type="file" accept="image/png, image/jpeg, image/jpg, .pdf" #fileDropRef id="fileDropRef" (change)="uploadFile($event.target.files)" enctype="multipart/form-data">
          <span (click)="fileDropRef.click()" class="background_img"></span>
        </div>
        <ng-template #uploadingBlock>
          <div class="progress_bar_container" >
            <section class="progress_bar_section">
              <mat-progress-bar mode="determinate" [value]="percentValue" class="progress_bar"></mat-progress-bar>
              <p class="progress_bar_text">{{percentDone}}</p>
            </section>
          </div>
        </ng-template>
        
      </div>

    </div>
  </div>

  <app-footer></app-footer>

  <div class="document_preview_container" *ngIf="showPreview">
    <!-- <div style="top: 50%;left: 50%;transform: translate(-50%, -50%);position: absolute;">
      <mat-spinner matSuffix [diameter]="40"> </mat-spinner>
    </div> -->
    <ng-container *ngIf="!isPreviewPdf">
      <div class="document_preview_img_header_container">
        <h5 class="document_preview_title" *ngIf="showFile" [innerHtml]="documentSelected.document_name"></h5>
        <h5 class="document_preview_title" *ngIf="showSharedDocFile" [innerHtml]="sharedDocumentSelected.document_name"></h5>
        <button class="document_preview_close_button" on-click="onClosePreviewClick()" title="Close Preview">
          <i class="fas fa-times" style="font-size: 26px;"></i>
        </button>
      </div>
      <div style="height: 100%;" *ngIf="showFile">
        <img style = "max-width: 50%;height: inherit;width: auto\9;" class="img_preview" [src]="documentSelected.document_link">
      </div>
      <div style="height: 100%;" *ngIf="showSharedDocFile">
        <img style = "max-width: 50%;height: inherit;width: auto\9;" class="img_preview" [src]="sharedDocumentSelected.document_link">
      </div>
    </ng-container>

    <ng-container *ngIf="isPreviewPdf">
      <button class="document_preview_pdf_close_button" on-click="onClosePreviewClick()" title="Close Preview">
        <i class="fas fa-times" style="font-size: 26px;"></i>
      </button>
      <iframe width="100%" height="100%" frameBorder="0" [src]="urlSafe"></iframe>
    </ng-container>
  </div>
</ng-container>

<ng-template #elseBlock>
  <mat-card id="teleConsultationVideoCallFrameWindow" class="row" style="width: 99.3%;min-height: 45%; display: none;padding: 4px;margin: 8px 0px 8px 5px;">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="display: inline-block;">
      <h1> IHL Tele Consultation </h1>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" id="teleConsultationVideoCallFrameleft" style="min-height: 300px;">
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" id="teleconsultationVideoCallEnd" style="min-height: 300px; display: none;background-color: rgba(0,0,0,0.5);z-index: 1;">
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" id="teleConsultationPtientDetails">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class = "Summary_list_main_container">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <h2 style="display: inline;">Medical Document Details</h2>
              <img src="assets/img/logo.png" style="float: right;height: 40px;"/>
            </div>                  
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 consultation_basic_details">
              <mat-card-header class="header-contents" >
              </mat-card-header>
            </div>
            <!-- <hr style="border-top: 1px solid darkgray;"> -->
            <mat-tab-group style="margin-top: 15px;" [selectedIndex]="0">
              <mat-tab label="Select a file" id="initiateMatTab"> 
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 consultation_note">
                  <div [ngClass]="{'selected-files': true, 'disable-container': isDeleteProgress}" *ngIf="!medFilePreview">
                    <div class = "teleconsult_doctor_list_common_header" >
                      <h5 class="list_files_header">Select a file
                        <ng-container *ngIf="enableMenuOptions">
                          <button *ngIf="enableView" type="button" class="close" (click)="onMedFileViewClick()" aria-label="Close" class="list_files_header_options">
                            <!-- <i class="fas fa-ellipsis-v"></i> -->
                            <i class="far fa-eye"></i>
                          </button>
                          <button type="button" class="close" (click)="onUploadClick(true)" aria-label="Close" class="list_files_header_options">
                            <i class="fa fa-upload"></i>
                          </button>
                        </ng-container>
                      </h5>
                    </div>
                    <div class="file-list border" >
                      <div  class="file_list_container">
                        <ng-container *ngIf="_constant.medicalDocumentsList.length > 0; else noFilesBlock">
                          <span *ngFor="let documnt of _constant.medicalDocumentsList; let i= index; trackBy: trackByFn;" [ngClass]="{'file_list_span': true}" >
                            <div class="round">
                              <input type="checkbox" id="checkbox_{{documnt.document_id}}" [checked]="documnt.is_checked" (click)="onDocumentSelection(documnt, i, $event)" />
                              <label for="checkbox_{{documnt.document_id}}"></label>
                            </div>
                            <img class="pic" src="{{getImageSrc(documnt)}}" alt="icon" style="width: 50px;" ><br>
                            <small [innerHtml] = "documnt.document_name"></small>
                          </span>
                        </ng-container>
                        <ng-template #noFilesBlock>
                          <p class ="no_files" *ngIf="isViewAllDocumentsResponseReceived">Documents not available</p>
                          <p class ="no_files" *ngIf="!isViewAllDocumentsResponseReceived" style="top: 150px;">
                            <mat-spinner matSuffix [diameter]="30" style="margin: auto;display: block;"> </mat-spinner>
                            Loading documents.Please wait...
                          </p>
                        </ng-template>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="medFilePreview">
                    <div class="med-file-list border" >
                      <div  class="file_list_container">
                        <ng-container *ngIf="!isMedFilePreviewPdf">
                          <div class="med_document_preview_img_header_container">
                            <h5 class="med_document_preview_title" [innerHtml] = "documentSelected.document_name"></h5>
                            <button class="med_document_preview_close_button" on-click="onCloseMedFilePreviewClick()" title="Close Preview">
                              <i class="fas fa-times" style="font-size: 26px;"></i>
                            </button>
                          </div>
                          <div style="height: 100%;">
                            <img style = "max-width: 100%;height: inherit;width: auto\9;" class="img_preview" [src]="documentSelected.document_link">
                          </div>
                        </ng-container>
                    
                        <ng-container *ngIf="isMedFilePreviewPdf">
                          <button class="document_preview_pdf_close_button" on-click="onCloseMedFilePreviewClick()" title="Close Preview">
                            <i class="fas fa-times" style="font-size: 26px;"></i>
                          </button>
                          <iframe width="100%" height="100%" frameBorder="0" [src]="urlSafe"></iframe>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div> 
              </mat-tab>
              <mat-tab label="Shared Document"> 
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 consultation_note">
                  <div [ngClass]="{'selected-files': true, 'disable-container': isDeleteProgress}" *ngIf="!sharedMedFilePreview">
                    <div class = "teleconsult_doctor_list_common_header" >
                      <h5 class="list_files_header">Shared Document
                        <ng-container *ngIf="enableShareDocMenuOptions">
                          <button type="button" class="close" (click)="onSharedMedFileViewClick()" aria-label="Close" class="list_files_header_options">
                            <!-- <i class="fas fa-ellipsis-v"></i> -->
                            <i class="far fa-eye"></i>
                          </button>
                        </ng-container>
                      </h5>
                    </div>
                    <div class="file-list border" >
                      <div  class="file_list_container">
                        <ng-container *ngIf="sharedDocumentsList.length > 0; else noFilesBlock">
                          <span *ngFor="let documnt of sharedDocumentsList; let i= index; trackBy: trackByFn;" (click)="onSharedDocumentSelectionClick(documnt, i)" [ngClass]="{'file_list_span': true, 'sharedDocActiveClass': (this.sharedActiveDocumentId == documnt.document_id)}" >
                            <img class="pic" src="{{getImageSrc(documnt)}}" alt="icon" style="width: 50px;" ><br>
                            <small [innerHtml] = "documnt.document_name"></small>
                          </span>
                        </ng-container>
                        <ng-template #noFilesBlock>
                          <p class ="no_files" *ngIf="isViewAllDocumentsResponseReceived">Documents not available</p>
                          <p class ="no_files" *ngIf="!isViewAllDocumentsResponseReceived" style="top: 150px;">
                            <mat-spinner matSuffix [diameter]="30" style="margin: auto;display: block;"> </mat-spinner>
                            Loading documents.Please wait...
                          </p>
                        </ng-template>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="sharedMedFilePreview">
                    <div class="med-file-list border" >
                      <div  class="file_list_container">
                        <ng-container *ngIf="!isSharedMedFilePreviewPdf">
                          <div class="med_document_preview_img_header_container">
                            <h5 class="med_document_preview_title" [innerHtml] = "sharedDocumentSelected.document_name"></h5>
                            <button class="med_document_preview_close_button" on-click="onCloseSharedMedFilePreviewClick()" title="Close Preview">
                              <i class="fas fa-times" style="font-size: 26px;"></i>
                            </button>
                          </div>
                          <div style="height: 100%;">
                            <img style = "max-width: 100%;height: inherit;width: auto\9;" class="img_preview" [src]="sharedDocumentSelected.document_link">
                          </div>
                        </ng-container>
                    
                        <ng-container *ngIf="isSharedMedFilePreviewPdf">
                          <button class="document_preview_pdf_close_button" on-click="onCloseSharedMedFilePreviewClick()" title="Close Preview">
                            <i class="fas fa-times" style="font-size: 26px;"></i>
                          </button>
                          <iframe width="100%" height="100%" frameBorder="0" [src]="urlSafe"></iframe>
                        </ng-container>
                      </div>
                    </div>
                  </div>

                </div>
              </mat-tab>
              <mat-tab label="Upload Document"> 
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 consultation_note">
                  <div class="dropZone" appDnd (onFileDropped)="uploadFile($event)" *ngIf="!uploadStarted; else uploadingBlock">
                    <input hidden type="file" accept="image/png, image/jpeg, image/jpg, .pdf" #fileDropRef id="fileDropRef" (change)="uploadFile($event.target.files)" enctype="multipart/form-data">
                    <span (click)="fileDropRef.click()" class="background_img"></span>
                  </div>
                  <ng-template #uploadingBlock>
                    <div class="progress_bar_container" >
                      <section class="progress_bar_section">
                        <mat-progress-bar *ngIf="showProgressBar" mode="determinate" [value]="percentValue" class="progress_bar"></mat-progress-bar>
                        <p class="progress_bar_text"  *ngIf="showProgressBar">{{percentDone}}</p>
                      </section>
                    </div>
                  </ng-template>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</ng-template>