
<!-- <div *ngIf="formatted">
<ngx-charts-line-chart
      [view]="view"
      [scheme]="colorScheme"
      [results]="single"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      >
  </ngx-charts-line-chart>

</div> -->
<!-- <br>
<div class="row">
  <div class="col-3">
    <app-stats-card-test [title]="'Heart rate'" [value]="'142'" [color]="'#8b0000'" [icon]="'fa-heart'"></app-stats-card-test>
  </div>
</div> -->

<!---input field tetsing purpose start-->
<!-- <div class="example-container">
  <mat-form-field appearance="fill">
    <mat-label>Input</mat-label>
    <input matInput>
  </mat-form-field>
  <br>
  <mat-form-field appearance="fill">
    <mat-label>Select</mat-label>
    <mat-select>
      <mat-option value="option">Option</mat-option>
    </mat-select>
  </mat-form-field>
  <br>
  <mat-form-field appearance="fill">
    <mat-label>Textarea</mat-label>
    <textarea matInput></textarea>
  </mat-form-field>
</div>



<form  [formGroup]="testForm" >
<mat-form-field>
  <input matInput [matDatepicker]="picker" placeholder="Input & change events"
         (dateInput)="addEvent('input', $event)" formControlName="date1" (dateChange)="addEvent('change', $event)">
  <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
</form>
<div class="example-events">
  <div *ngFor="let e of events">{{e}}</div>
</div>




<app-upload></app-upload>



<div class="dateSlot">
              <div class="dateBox">  
                  <p class="dateBoxTitle">Today, 19 May</p>
                  <p class="noSlotAva"> No Slots available </p>
              </div>
              <div class="dateBox">
                  <p class="dateBoxTitle">Mon, 25 May</p>
                  <p class="slotAva"> 20 Slots available </p>
              </div>
              <div class="dateBox">  
                  <p class="dateBoxTitle">Tue, 26 May</p>
                  <p class="slotAva"> 20 Slots available </p>
              </div>
              <div class="dateBox">  
                  <p class="dateBoxTitle">Wed, 27 May</p>
                  <p class="slotAva"> 20 Slots available </p>
              </div>
              <div class="dateBox">  
                  <p class="dateBoxTitle">Thu, 28 May</p>
                  <p class="slotAva"> 20 Slots available </p>
              </div> 
              <div class="dateBox">  
                  <p class="dateBoxTitle">Thu, 28 May</p>
                  <p class="slotAva"> 20 Slots available </p>
              </div> 
              <div class="dateBox">  
                  <p class="dateBoxTitle">Thu, 28 May</p>
                  <p class="slotAva"> 20 Slots available </p>
              </div> 
              <div class="dateBox">  
                  <p class="dateBoxTitle">Thu, 28 May</p>
                  <p class="slotAva"> 20 Slots available </p>
              </div> 
            </div> -->

            <div class="scrolling-wrapper">
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
              <div class="card"><h2>Card</h2></div>
            </div>