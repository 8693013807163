<div class="leaderboard-card">
  <div class="row">
    <div class="col-sm-2">
      🎖
    </div>
    <div class="col-sm-7">
      {{name}}
    </div>
    <div class="col-sm-3">
        {{points}}
      </div>
  </div>
</div>
