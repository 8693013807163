<div class="card d-flex" style="text-align: center;">
  <h6 style="text-transform: uppercase; font-weight: 700; letter-spacing: 1px;">Posted by</h6>
  <p>{{postedBy}}</p>
  <p style="margin: 0 auto !important"><span style="font-size: 2rem;"class="fas fa-trophy"></span></p>
  <p style="margin: 0 auto !important">{{points}} points</p>
  <a (click)="click()"class="take btn btn-secondary">{{take}}</a>
  <div class="align-self-bottom">
    <p style="float: left;">{{challenge}}</p>
    <p style="float: right;">{{time}}</p>
  </div>
</div>