<div class="container-fluid"> 
  <div class="row no-gutter"> 
    <div class="col-md-8 d-none d-md-flex bg-image">
    </div> 
    <div class="col-md-4 bg-light"> 
      <div class="login d-flex py-5"> 
        <div class="container"> 
          <div class="row"> 
            <div class="col-lg-7 col-xl-12">
              <div class="login-logo"> </div>
              <form class="form" #forgotForm="ngForm" (ngSubmit)="forgotForm.form.valid && onForgotPasswordSubmit()" novalidate>
                <div class="form-group">
                  <span class="form-title">Forgot Password</span>
                </div>
                <div class="form-group">
                  <input type="text" class="form-control" [(ngModel)]="userModal.email" name="email" #email="ngModel" placeholder="Email" required email>
                  <span class="text-danger" *ngIf="(email.touched || forgotForm.submitted) && email.errors?.required">
                      Email is required
                  </span>
                  <span class="text-danger" *ngIf="email.touched && email.errors?.email">
                      Enter a valid email address
                  </span>
                  <!-- <span class="text-danger" *ngIf="isEmailExist">
                    Email already exists.
                  </span> -->
                </div>
                <div class="form-group" *ngIf="mailStatus != ''">
                  <p class="alert alert-success" *ngIf="mailSent">
                    <strong>{{mailStatus}}</strong>
                  </p>
                  <p class="alert alert-danger" *ngIf="!mailSent">
                    <strong>{{mailStatus}}</strong>
                  </p>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <button class="btn btn-primary" type="submit">Submit <span *ngIf="isLoading" class="loading-screen"><i class="fas fa-spinner fa-pulse" aria-hidden="true"></i></span></button>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <button class="btn btn-primary" type="button" (click)="showLoginPage()">Back</button>
                    </div>
                  </div>
                </div>
              </form>
            </div> 
          </div> 
        </div> 
      </div> 
    </div> 
  </div>
</div>
